import * as yup from 'yup';
import { OptionalObjectSchema, RequiredObjectSchema } from 'yup/lib/object';
import { TaskTypes } from '../../../../utils/constants';

const baseTaskTypeSchema = {
  title: yup.string().min(3).required('Title is required'),
  dueDate: yup.date().required('Please enter a date').nullable().default(null),
  status: yup.string().required(),
  content: yup.object(),
  taskTypeId: yup.number().required('Task type is required'),
};

export const unselectedTaskTypeSchema = yup.object(baseTaskTypeSchema);

export const schemaForPropUpTask = yup
  .object({
    ...baseTaskTypeSchema,
    productTaxonomy: yup.string().required(),
    havenlyAssetId: yup.string().nullable().default(null),
    similarPropUrl: yup.string().url().nullable().default(null),
    kustomerId: yup.string().nullable().default(null),
    sourceUrl: yup.string().url().required('Source URL is required'),
    requesterId: yup.string().required(),
    custom: yup.string().nullable().default(null),
    vendorVariantImageUrl: yup.string().url().required('Image URL is required'),
    vendorVariantTitle: yup.string().required('Title is required'),
    baseAssetHash: yup.string().nullable().default(null),
    assetUrl: yup.string().url().nullable().default(null),
    assetMetadata: yup.string().nullable().default(null),
  })
  .required();

export const schemaForRoomShellTask = yup
  .object({
    ...baseTaskTypeSchema,
    havenlyAssetId: yup.string().nullable().default(null),
    dimensions: yup.string().nullable().default(null),
    kustomerId: yup.string().nullable().default(null),
    requesterId: yup.string().required(),
    floorPlanImages: yup.array().of(yup.object({ url: yup.string().url() })),
    roomImages: yup.array().of(yup.object({ url: yup.string().url() })),
    custom: yup.string().nullable().default(null),
  })
  .required();

export const schemaForTouchUpTask = yup.object({
  ...baseTaskTypeSchema,
  renderRequestId: yup.string().min(2).required('Render request id is required'),
  roomProfileImages: yup.array().of(yup.object({ url: yup.string().url() })),
  approvedQARenders: yup
    .array()
    .of(yup.object({ url: yup.string().url().required('Render URL is required') })),
  latestPublishedConceptBoard: yup.string().url().required(),
  renderDataPayload: yup.object().nullable().default(null),
});

export const schemaForExtraTask = yup
  .object({
    ...baseTaskTypeSchema,
    havenlyAssetId: yup.string().required(),
    requesterId: yup.string().required(),
    categoryId: yup.string().required(),
    creatorCategoryId: yup.string().required(),
    extraTitle: yup.string().required(),
    baseAssetHash: yup.string().nullable().default(null),
    assetUrl: yup.string().url().nullable().default(null),
    thumbnailUrl: yup.string().url().nullable().default(null),
    assetMetadata: yup.string().nullable().default(null),
  })
  .required();

  export const schemaForMaterialTask = yup
  .object({
    ...baseTaskTypeSchema,
    havenlyAssetId: yup.string().required(),
    requesterId: yup.string().required(),
    categoryId: yup.string().required(),
    materialTitle: yup.string().required(),
    assetUrl: yup.string().url().nullable().default(null),
    thumbnailUrl: yup.string().url().nullable().default(null),
    assetMetadata: yup.string().nullable().default(null),
  })
  .required();

export const getSchema = (taskType: TaskTypes | null): RequiredObjectSchema<any, any, any> | OptionalObjectSchema<any, any, any> => {
  switch (taskType) {
    case TaskTypes['Prop']:
      return schemaForPropUpTask;
    case TaskTypes['Touch Up']:
      return schemaForTouchUpTask;
    case TaskTypes.Extra:
      return schemaForExtraTask;
    case TaskTypes.Material:
      return schemaForMaterialTask;
  }

  return unselectedTaskTypeSchema;
};
