import { yupResolver } from '@hookform/resolvers/yup';
import CloseIcon from '@mui/icons-material/Close';
import { LoadingButton } from '@mui/lab';
import { Grid, IconButton } from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { useMassAssignDueDateToTasksMutation } from '../../../../generated/graphql';
import { ControlledDatePicker } from '../../../common/ControlledDatePicker/ControlledDatePicker';

export const dueDateSchema = yup
  .object({
    dueDate: yup.date().required('Please enter a date').nullable().default(null),
  })
  .required();

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 540,
  maxWidth: '100%',
  bgcolor: 'background.paper',
  border: '2px solid grey',
  boxShadow: 24,
  p: 5,
  height: { xs: '100vh', md: '50vh' },
  overflow: 'auto',
};

interface AssignOwnersToTaskProps {
  selectedTaskIds: number[];
  onResetSelectedTaskIds: () => void;
}

export const AssignDueDateToTasks: React.FC<AssignOwnersToTaskProps> = ({
  selectedTaskIds,
  onResetSelectedTaskIds,
}) => {
  const [open, setOpen] = useState(false);
  const [dueDate, setDueDate] = useState(null);

  const [mutate, { loading }] = useMassAssignDueDateToTasksMutation();

  const handleModal = (): void => {
    setOpen(!open);
  };

  const { control, handleSubmit } = useForm({
    mode: 'all',
    resolver: yupResolver(dueDateSchema),
    defaultValues: {
      dueDate: null,
    },
  });

  const onSubmit = async (values: any) => {
    mutate({
      variables: {
        data: {
          dueDate: values.dueDate as Date,
          taskIds: selectedTaskIds,
        },
      },
      onCompleted: () => {
        handleModal();
        onResetSelectedTaskIds();
      },
    });
  };

  return (
    <div>
      <Grid container justifyContent="flex-end">
        <Box display="flex" justifyContent="flex-end">
          <Button onClick={handleModal} variant="outlined">
            Assign Due Date
          </Button>
        </Box>
      </Grid>
      <Modal
        open={open}
        onClose={() => {
          handleModal();
          onResetSelectedTaskIds();
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <IconButton
            sx={{ position: 'absolute', top: 4, right: 4 }}
            onClick={() => {
              handleModal();
              onResetSelectedTaskIds();
            }}
            aria-label="close modal"
            color="primary"
          >
            <CloseIcon />
          </IconButton>
          {open && (
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
                height: '100%',
              }}
            >
              <form
                style={{
                  height: '100%',
                  display: 'flex',
                  justifyContent: 'space-between',
                  flexDirection: 'column',
                }}
                onSubmit={handleSubmit(onSubmit)}
              >
                <ControlledDatePicker
                  control={control}
                  name="dueDate"
                  rules={{ required: true }}
                  value={dueDate}
                  label="Due date"
                  required
                  onChange={setDueDate}
                />

                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                    gap: '15px',
                  }}
                >
                  <Button
                    onClick={() => {
                      handleModal();
                      onResetSelectedTaskIds();
                    }}
                    variant="outlined"
                  >
                    Cancel
                  </Button>
                  <LoadingButton loading={loading} type="submit" variant="contained">
                    Save
                  </LoadingButton>
                </Box>
              </form>
            </Box>
          )}
        </Box>
      </Modal>
    </div>
  );
};
