import React, { useContext, useState } from 'react';
import { Alert, Box, Button, Modal, TextField, Typography } from '@mui/material';
import {
  TaskQuery,
  useReviseTaskMutation as useReviseTask,
  CommentsByTaskIdDocument,
  TaskDetailsDocument
} from '../../../../generated/graphql';
import { SnackbarContext } from '../../../../contexts/Snackbar/SnackbarProvider';

const modalStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
};

const commentStyle = {
  width: '100%',
}

interface ReviseModalProps {
  open: boolean;
  handleClose: () => void;
  task: TaskQuery['task'];
}

export const ReviseModal: React.FC<ReviseModalProps> = ({
  open, handleClose, task
}) => {
  const [reviseTask, {loading , error }] = useReviseTask();
  const [comment, setComment] = useState<string>('');
  const { snackThis } = useContext(SnackbarContext)

  const handleTextFieldChange = (event: React.ChangeEvent<HTMLTextAreaElement>) =>{
    setComment(event.target.value);
  }

  const canSubmit = !!comment.length && !loading;

  const submit = async () => {
    await reviseTask({
      refetchQueries: [CommentsByTaskIdDocument, TaskDetailsDocument],
      variables: {
        taskId: task.id.toString(),
        comment
      }
    });
    snackThis('Created revision task successfully');
    setComment('');
    handleClose();
  }

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={modalStyle}>
        <Typography id="modal-modal-title" variant="h6" component="h2">
          Create revision task for {task.title}
        </Typography>

        <Typography id="modal-modal-description" sx={{ mt: 2 }}>
          Provide details for the revision:
        </Typography>

        <TextField
          multiline
          style={commentStyle}
          onChange={handleTextFieldChange}
          rows={3}
          sx={{ mt: 2}}
        />

        { (error) && (
          <Alert sx={{ mt: 2 }} severity="error">
            {`Error creating revision ${error}`}
          </Alert>
        )}

        <Button
          disabled={!canSubmit}
          variant="contained"
          style={{ marginTop: '15px'}}
          onClick={submit}
        >
          submit
        </Button>

      </Box>
    </Modal>
  );
}
