import { ApolloProvider } from "@apollo/client";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import CssBaseline from "@mui/material/CssBaseline";
import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import { App } from "./components/App/App";
import { client } from "./utils/apolloClient";
import { Theme } from "./utils/theme";
import { SnackbarProvider } from "./contexts/Snackbar/SnackbarProvider";

ReactDOM.render(
  <React.StrictMode>
    <ApolloProvider client={client}>
      <SnackbarProvider>
        <Theme>
          <CssBaseline />
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <BrowserRouter>
              <App />
            </BrowserRouter>
          </LocalizationProvider>
        </Theme>
      </SnackbarProvider>
    </ApolloProvider>
  </React.StrictMode>,
  document.getElementById("root")
);
