import { FormControl, FormHelperText, InputLabel, MenuItem, Select } from '@mui/material';
import React from 'react';
import { useTaskTypesQuery } from '../../../../generated/graphql';

interface TaskTypeSelectProps {
  taskTypeId: number | null;
  setTaskTypeId: (taskTypeId: number | null) => void;
  errors: any;
  register: any;
}

export const TaskTypeSelect: React.FC<TaskTypeSelectProps> = ({
  taskTypeId,
  setTaskTypeId,
  errors,
  register,
}) => {
  const { loading, error, data } = useTaskTypesQuery();

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error</div>;
  }

  return (
    <FormControl fullWidth>
      <InputLabel id="demo-simple-select-label">Task Type</InputLabel>
      <Select
        labelId="demo-simple-select-label"
        id="demo-simple-select"
        value={taskTypeId || ''}
        label="Task Type"
        inputProps={register('taskTypeId')}
        error={errors.taskTypeId}
        onChange={(event) => {
          const value = event.target.value as number;
          setTaskTypeId(value);
        }}
      >
        {data?.taskTypes?.map((taskType) => {
          return (
            <MenuItem key={taskType.id} value={taskType.id}>
              {taskType.name}
            </MenuItem>
          );
        })}
      </Select>
      {errors.taskTypeId?.message && <FormHelperText>{errors.taskTypeId.message}</FormHelperText>}
    </FormControl>
  );
};
