import create, { GetState, SetState } from 'zustand';
import { persist, StoreApiWithPersist } from 'zustand/middleware';
import { AuthSlice, authSlice } from './authSlice';
import { TaskTableSlice, taskTableSlice } from './taskTableSlice';
import { themeSlice, ThemeSlice } from './themeSlice';
import { FiltersSlice, filters } from './filters';
import shallow from 'zustand/shallow';

export type StoreState = ThemeSlice & AuthSlice & TaskTableSlice & FiltersSlice;

export type StoreSlice<T> = (set: SetState<StoreState>, get: GetState<StoreState>) => T;

export const useStore = create(
  persist<StoreState, SetState<StoreState>, GetState<StoreState>, StoreApiWithPersist<StoreState>>(
    (set, get) => ({
      ...themeSlice(set, get),
      ...authSlice(set, get),
      ...taskTableSlice(set, get),
      ...filters(set, get),
    }),
    {
      name: 'lunes-store',
      partialize: (state) => ({
        theme: state.theme,
        accessToken: state.accessToken,
        refreshToken: state.refreshToken,
        gridColumnVisibilityModel: state.gridColumnVisibilityModel,
        filters: state.filters,
        user: state.user,
      }),
    },
  )
);

export const useEZStore = () => useStore((state) => state, shallow);