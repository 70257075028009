import { GridColumnVisibilityModel } from '@mui/x-data-grid';
import { StoreSlice } from './store';

export type TaskTableSlice = {
  gridColumnVisibilityModel: GridColumnVisibilityModel | undefined;
  setGridColumnVisibilityModel: (gridColumnVisibilityModel: any) => void;
};

export const taskTableSlice: StoreSlice<TaskTableSlice> = (set) => ({
  // visible columns by default
  gridColumnVisibilityModel: {
    __check__: true,
    id: false,
    parentTaskId: false,
    title: true,
    content: true,
    status: true,
    similarPropUrl: true,
    sourceUrl: true,
    owners: true,
    dueDate: true,
    createdAt: true,
  },
  setGridColumnVisibilityModel: (gridColumnVisibilityModel) => set({ gridColumnVisibilityModel }),
});
