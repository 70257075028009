import Brightness4Icon from '@mui/icons-material/Brightness4';
import Brightness7Icon from '@mui/icons-material/Brightness7';
import { Chip } from '@mui/material';
import AppBar from '@mui/material/AppBar';
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import IconButton from '@mui/material/IconButton';
import Link from '@mui/material/Link';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Toolbar from '@mui/material/Toolbar';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import React, { useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import shallow from 'zustand/shallow';
import { useMeQuery, CognitoGroups } from '../../generated/graphql';
import { useStore } from '../../store/store';

export const Header: React.FC = () => {
  const { theme, toggleTheme, accessToken } = useStore(
    (state) => ({
      theme: state.theme,
      accessToken: state.accessToken,
      toggleTheme: state.toggleTheme,
    }),
    shallow,
  );
  const { loading, error, data } = useMeQuery({ skip: accessToken === null });

  const [, setAnchorElNav] = useState<null | HTMLElement>(null);
  const [anchorElUser, setAnchorElUser] = useState<null | HTMLElement>(null);

  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const signOutUrl = `${process.env.REACT_APP_AWS_COGNITO_AUTH}/logout?client_id=${process.env.REACT_APP_AWS_COGNITO_CLIENT_ID}&logout_uri=${process.env.REACT_APP_AWS_COGNITO_CALLBACK_SIGN_OUT}`;

  const userGroups = data?.me?.groups;

  const isDevOrLocalEnv = ['dev', 'local'].includes(
    process.env.REACT_APP_ENV ? process.env.REACT_APP_ENV : '',
  );

  const canAssignGroups = userGroups?.includes(CognitoGroups.HavenlyAdmin);

  return (
    <AppBar position="static">
      <Container maxWidth="xl">
        <Toolbar disableGutters sx={{ justifyContent: 'space-between' }}>
          <Typography
            variant="h6"
            noWrap
            component="div"
            sx={{ mr: 2, display: { xs: 'none', md: 'flex' } }}
          >
            <Link component={RouterLink} to="/" sx={{ color: 'white' }}>
              Lunes
            </Link>
            {isDevOrLocalEnv && (
              <Chip label="DEVELOPMENT" sx={{ ml: 2, color: 'white', background: 'black' }} />
            )}
          </Typography>

          <Typography
            variant="h6"
            noWrap
            component="div"
            sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}
          >
            Lunes
            {isDevOrLocalEnv && (
              <Chip label="DEVELOPMENT" sx={{ ml: 2, color: 'white', background: 'black' }} />
            )}
          </Typography>

          <Box sx={{ flexGrow: 0 }}>
            <IconButton sx={{ mr: 1 }} onClick={toggleTheme} color="inherit">
              {theme === 'dark' ? <Brightness7Icon /> : <Brightness4Icon />}
            </IconButton>
            <Tooltip title="Open settings">
              <>
                {!loading && !error && data && userGroups && (
                  <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                    <Avatar alt={data.me.name} src="/static/images/avatar/2.jpg" />
                  </IconButton>
                )}
              </>
            </Tooltip>
            <Menu
              sx={{ mt: '45px' }}
              id="menu-appbar"
              anchorEl={anchorElUser}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              open={Boolean(anchorElUser)}
              onClose={handleCloseUserMenu}
            >
              <MenuItem sx={{ justifyContent: 'end' }} onClick={handleCloseNavMenu}>
                <Typography>{data && data?.me?.name}</Typography>
              </MenuItem>
              <MenuItem sx={{ justifyContent: 'end' }} onClick={handleCloseUserMenu}>
                <Link component={RouterLink} to="/dashboard">
                  Dashboard
                </Link>
              </MenuItem>

              {canAssignGroups && (
                <MenuItem sx={{ justifyContent: 'end' }} onClick={handleCloseUserMenu}>
                  <Link component={RouterLink} to="/manage-users">
                    Manage Users
                  </Link>
                </MenuItem>
              )}

              <MenuItem sx={{ justifyContent: 'end' }} onClick={handleCloseUserMenu}>
                <Link href={signOutUrl}>Sign Out</Link>
              </MenuItem>
            </Menu>
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
};
