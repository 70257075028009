import React, { FC, useContext } from 'react';
import BaseModal, { BaseModalProps } from './BaseModal';
import {
  Box,
  Button,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@mui/material';
import { Control, Controller, FieldErrors, useForm } from 'react-hook-form';
import { useMeQuery } from '../generated/graphql';
import { SnackbarContext } from '../contexts/Snackbar/SnackbarProvider';
import { isHavenlyAdmin } from '../utils/groups';
import { EmotionJSX } from '@emotion/react/types/jsx-namespace';
import useCreateUser from './useCreateUser';
import { PrivilegeLevels, Tenants } from '../constants/tenants';

const TenantOptions = {
  Havenly: Tenants.Havenly,
  'Sonny And Ash': Tenants.SonnyAndAsh,
  eSoft: Tenants.eSoft,
  'Havenly In House': Tenants.HavenlyInHouse,
};

export interface FormValues {
  name: string;
  email: string;
  tenant?: Tenants;
  privilegeLevel?: PrivilegeLevels;
}

export const CreateUserModal: FC<BaseModalProps> = ({ isModalOpen, onClose }) => {
  const {
    control,
    formState: { errors },
    register,
    handleSubmit,
    reset,
  } = useForm<FormValues>();
  const { snackThis, errorThis } = useContext(SnackbarContext);
  const { data } = useMeQuery();
  const { createUserMutation, loading } = useCreateUser();

  const canPickTenant = isHavenlyAdmin(data);

  const onSubmit = async (formData: FormValues) => {
    try {
      await createUserMutation(formData, data?.me);
      snackThis('User created successfully');
      onClose();
      reset();
    } catch (e: any) {
      errorThis(`Error creating user: ${e.message}`);
    }
  };

  return (
    <BaseModal isModalOpen={isModalOpen} onClose={onClose}>
      <Box>
        <h3>Create Lunes User</h3>

        <form onSubmit={handleSubmit(onSubmit)}>
          <TextField
            label="Name"
            fullWidth
            margin="normal"
            {...register('name', { required: true })}
            error={!!errors.name}
            helperText={errors.name && 'Name is required'}
          />
          <TextField
            label="Email"
            fullWidth
            margin="normal"
            {...register('email', {
              required: true,
              pattern: /^[+a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/i,
            })}
            error={!!errors.email}
            helperText={errors.email && 'Please provide a valid email address'}
          />

          {canPickTenant && (
            <Dropdown
              control={control}
              label="Tenant"
              itemsList={Object.entries(TenantOptions)}
              name="tenant"
              errors={errors}
            />
          )}

          <Dropdown
            control={control}
            label="Privilege Level"
            itemsList={Object.entries(PrivilegeLevels)}
            name="privilegeLevel"
            errors={errors}
          />

          <Button
            disabled={loading}
            variant="contained"
            color="primary"
            type="submit"
            sx={{ my: 2 }}
          >
            create
          </Button>
        </form>
      </Box>
    </BaseModal>
  );
};

type Option = [string, string];

interface DropdownProps {
  control: Control<FormValues>;
  label: string;
  itemsList: Option[];
  name: keyof FormValues;
  errors: FieldErrors<FormValues>;
}

function Dropdown({ control, label, itemsList, name, errors }: DropdownProps) {
  return (
    <FormControl fullWidth margin="normal">
      <InputLabel id={name}>{label}</InputLabel>
      <Controller
        rules={{ required: true }}
        name={name}
        control={control}
        render={renderOptions(itemsList)}
      />
      {!!errors[name] && <FormHelperText color="red">{label} is required</FormHelperText>}
    </FormControl>
  );
}

function renderOptions(options: Option[]): (props: any) => EmotionJSX.Element {
  return ({ field }) => (
    <Select labelId="select-label" {...field}>
      {options.map(([name, id]) => (
        <MenuItem value={id}>{name}</MenuItem>
      ))}
    </Select>
  );
}
