import { Box } from '@mui/material';
import React from 'react';
import { ControlledTextField } from '../../ControlledTextField/ControlledTextField';

interface MaterialTaskFormProps {
  control: any;
  watch: any;
}

export const MaterialTaskForm: React.FC<MaterialTaskFormProps> = ({ control }) => {
  return (
    <Box
      sx={{
        marginTop: '15px',
        display: 'grid',
        gridTemplateColumns: { xs: '1fr', md: '1fr 1fr' },
        gridGap: '15px',
      }}
    >
      <ControlledTextField label="Havenly Asset ID" name="havenlyAssetId" control={control} required />
      <ControlledTextField label="Requester ID" name="requesterId" control={control} required />
      <ControlledTextField label="Category ID" name="categoryId" control={control} required />
      <ControlledTextField label="Material Title" name="materialTitle" control={control} required />
      <ControlledTextField label="Asset URL" name="assetUrl" control={control} />
      <ControlledTextField label="Thumbnail URL" name="thumbnailUrl" control={control} />
      <ControlledTextField label="Asset Metadata" name="assetMetadata" control={control} />
    </Box>
  );
};
