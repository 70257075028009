import { FC, ReactElement, useContext } from 'react';
import { CircularProgress, ListItemIcon, ListItemText, MenuItem } from '@mui/material';
import { SnackbarContext } from '../../../../contexts/Snackbar/SnackbarProvider';
import { TaskDetailsDocument } from '../../../../generated/graphql';

interface BaseMenuItemProps {
  menuClick: () => void;
  loading: boolean;
  icon: ReactElement;
  text: string;
}

export const BaseMenuItem: FC<BaseMenuItemProps> = ({
  menuClick, loading, icon, text
}) => {
  
  return (
    <>
      <MenuItem onClick={menuClick} disabled={loading}>
        <ListItemIcon>
          { icon }
        </ListItemIcon>

        <ListItemText>
          { text }
        </ListItemText>
        
        { loading && <CircularProgress size={16} style={{ marginLeft: '16px'}} /> }
      </MenuItem>

    </>
  );
}

export const refetchQueries = [TaskDetailsDocument];

export function useBaseMenuAction(
  mutation: () => void, 
  handleMenuClick: () => void,
  successMessage: string,
  errorMessage: string
) {
    const { snackThis, errorThis } = useContext(SnackbarContext)

    const action = async () => {
      try {
        await mutation();
        snackThis(successMessage);
      } catch (error ) {
        errorThis(`${errorMessage}: ${error}`);
      } finally {
        handleMenuClick();
      }
    }
    
    return { action };
}