import { TaskTypes } from "../../../../utils/constants";

export const initialPayload = (payload: any, taskTypeId: number) => {
  if (taskTypeId === TaskTypes["Touch Up"]) {
    const { roomProfileImages, approvedQARenders } = payload;

    return {
      ...payload,
      roomProfileImages: roomProfileImages
        ? roomProfileImages.split(',').map((url: string) => ({ url }))
        : [{ url: '' }],
      approvedQARenders: approvedQARenders
        ? approvedQARenders.split(',').map((url: string) => ({ url }))
        : [{ url: '' }],
    };
  }

  return payload;
};

export const initialPayloadNewTask = () => {
  return {
    roomProfileImages: [{ url: '' }],
    approvedQARenders: [{ url: '' }],
    floorPlanImages: [{ url: '' }],
    roomImages: [{ url: '' }],
  };
};
