import axios from 'axios';
import React, { useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import shallow from 'zustand/shallow';
import { LoadingScreen } from '../../components/common/LoadingScreen/LoadingScreen';
import { useMeLazyQuery } from '../../generated/graphql';
import { useStore } from '../../store/store';

export const SignIn: React.FC = () => {
  const { setAccessToken, setRefreshToken, setUser, accessToken } = useStore(
    (state) => ({
      setRefreshToken: state.setRefreshToken,
      setAccessToken: state.setAccessToken,
      setUser: state.setUser,
      accessToken: state.accessToken,
    }),
    shallow,
  );

  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const code = searchParams.get('code');

  const [meQuery] = useMeLazyQuery();

  useEffect(() => {
    if (!code) {
      return;
    }

    const getToken = async () => {
      const data =
        `grant_type=authorization_code` +
        `&client_id=${process.env.REACT_APP_AWS_COGNITO_CLIENT_ID}` +
        `&code=${code}` +
        `&redirect_uri=${process.env.REACT_APP_AWS_COGNITO_CALLBACK_SIGN_IN}`;

      try {
        const response = await axios.post(
          `${process.env.REACT_APP_AWS_COGNITO_AUTH}/oauth2/token`,
          data,
          {
            headers: {
              'Content-Type': 'application/x-www-form-urlencoded',
            },
          },
        );
        setAccessToken(response.data.access_token);
        setRefreshToken(response.data.refresh_token);
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error(error);
      }
    };

    getToken();
  }, [code, setAccessToken, setRefreshToken]);

  useEffect(() => {
    const getMe = async () => {
      try {
        const meData = await meQuery();

        if (meData?.data?.me?.groups && meData.data.me.groups.length > 0) {
          setUser(meData.data);
          navigate('/dashboard');
        } else {
          navigate('/');
        }
      } catch (e) {
        navigate('/');
      }
    };

    if (accessToken) {
      getMe();
    }
  }, [accessToken, meQuery, navigate, setUser]);

  return <LoadingScreen fullScreen />;
};
