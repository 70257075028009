import React from 'react';
import { Grading } from '@mui/icons-material';
import { ActionMenuItemProps } from './MenuInterface';
import { ListItemIcon, ListItemText, MenuItem } from '@mui/material';

export const ReviseMenuItem: React.FC<ActionMenuItemProps> = ({
  handleMenuClick
}) => {

  return (
    <>
      <MenuItem onClick={handleMenuClick}>
        <ListItemIcon>
          <Grading fontSize='small' />
        </ListItemIcon>

        <ListItemText>
          Create Revision
        </ListItemText>
      </MenuItem>
    </>
  );
};
