import { Box, Button, Chip, CircularProgress, Divider } from '@mui/material';
import Back from '@mui/icons-material/ArrowBackIos';
import React from 'react';
import { TaskStatuses, TaskDetailsQuery, useTaskDetailsQuery } from '../../generated/graphql';
import { Breadcrumb } from './Breadcrumb/Breadcrumb';
import { Comments } from './Comments/Comments';
import { CreateCommentForm } from './CreateCommentForm/CreateCommentForm';
import { CreateSubtaskForm } from './CreateSubTaskForm/CreateSubtaskForm';
import { Task } from './Task/Task';
import { useNavigate } from 'react-router-dom';
import { useEZStore } from '../../store/store';
import { isHavenlyAdminOrUser } from '../../utils/groups';

interface TaskDetailsProps {
  taskId: string;
}

export const TaskDetails: React.FC<TaskDetailsProps> = ({ taskId }) => {
  const { loading, error, data } = useTaskDetailsQuery({
    variables: {
      id: Number(taskId),
    },
  });
  const navigate = useNavigate();
  const { user } = useEZStore();

  const canEditTasks = isHavenlyAdminOrUser(user);
  
  if (loading) {
    return (
      <Box sx={{ display: 'flex' }}>
        <CircularProgress sx={{ margin: '30px auto' }} />
      </Box>
    );
  }

  if (error || !data) {
    return <div>error, {error?.message}</div>;
  }

  const isSubtask = data.task.parentTaskId !== null;

  return (
    <Box>
      <Button onClick={ () => navigate(-1) }>
        <Back /> back
      </Button>
      {/* Breadcrumbs */}
      {isSubtask && (
        <Breadcrumb id={data.task.id} parentTaskId={data.task.parentTaskId as number} />
      )}

      {/* Display Task*/}
      <Task task={data.task} editable={canEditTasks} />

      {/* Create Subtask, only Complete and Cancelled status are allowed to create Subtasks */}
      { shouldShowSubtaskForm(isSubtask, data) && (
        <CreateSubtaskForm taskId={data.task.id} />
      )}

      {/* Show comments */}
      <Divider sx={{ my: 2 }}>
        <Chip label="Comments" />
      </Divider>
      <Comments taskId={data.task.id} />

      {/* Add Comment */}
      <CreateCommentForm taskId={data.task.id} />
    </Box>
  );
};

function shouldShowSubtaskForm(isSubtask: boolean, data: TaskDetailsQuery) {
  if (isSubtask) return false;
  if (!data.task?.status) return false;
  
  return [TaskStatuses.Complete, TaskStatuses.Cancelled].includes(data.task.status);
}