import { MeQuery } from '../generated/graphql';
import { StoreSlice } from './store';

export type AuthSlice = {
  accessToken: string | null;
  refreshToken: string | null;
  user?: MeQuery;
  setAccessToken: (accessToken: string | null) => void;
  setRefreshToken: (refreshToken: string | null) => void;
  setUser: (user: MeQuery) => void;
};

export const authSlice: StoreSlice<AuthSlice> = (set) => ({
  accessToken: null,
  refreshToken: null,
  user: undefined,
  setAccessToken: (accessToken) => set({ accessToken }),
  setRefreshToken: (refreshToken) => set({ refreshToken }),
  setUser: (user) => set({ user })
});
