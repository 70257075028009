import React from 'react';
import { PlayCircleFilled } from '@mui/icons-material';
import { ActionMenuItemProps } from './MenuInterface';
import { useBeginTaskMutation } from '../../../../../generated/graphql';
import { BaseMenuItem, useBaseMenuAction, refetchQueries } from '../BaseMenuItem';

export const BeginMenuItem: React.FC<ActionMenuItemProps> = ({
  handleMenuClick, task 
}) => {
  const [beginTask, { loading }] = useBeginTaskMutation();
  const { action } = useBaseMenuAction(
    () => beginTask({
      refetchQueries,
      variables: {
        taskId: task.id.toString()
      }
    }),
    handleMenuClick,
    'Task started successfully',
    'Error starting task'
  )

  return (
    <BaseMenuItem
      menuClick={action}
      loading={loading}
      icon={<PlayCircleFilled fontSize="small" />}
      text="Begin"
    />
  );
}
