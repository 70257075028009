import styled from '@emotion/styled';
import { LoadingButton } from '@mui/lab';
import { FormLabel, LinearProgress } from '@mui/material';
import { convertToRaw } from 'draft-js';
import React, { useCallback, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { CommentsByTaskIdDocument, useCreateCommentMutation } from '../../../generated/graphql';
import { createInCacheWithVars } from '../../../utils/createInCacheWithVars';
import { convertDraftStateToString, getInitialDraftValue } from '../../../utils/helper';
import { DraftRichText } from '../../common/DraftRichText/DraftRichText';
interface CreateCommentFormProps {
  taskId: number;
  content?: string;
}

const Wrapper = styled.div`
  .ql-editor {
    min-height: 150px;
  }
`;

export const CreateCommentForm: React.FC<CreateCommentFormProps> = ({ taskId }) => {
  const [mutate, { loading }] = useCreateCommentMutation();
  const [mentionedUsers, setMentionedUsers] = useState<{ id: number; name: string }[]>([]);

  const {
    reset,
    register,
    setValue,
    handleSubmit,
    watch,
    formState: { isSubmitting, errors },
  } = useForm({ mode: 'onChange', defaultValues: { editorContent: getInitialDraftValue('') } });

  const editorContent = watch('editorContent');

  useEffect(() => {
    register('editorContent', { required: true, minLength: 11 });
  }, [register]);

  // To be used when having mutation to notify users
  const extractMentions = useCallback(() => {
    const contentState = editorContent?.getCurrentContent();
    const raw = convertToRaw(contentState);
    let mentionedUsers = [];
    for (let key in raw.entityMap) {
      const ent = raw.entityMap[key];
      if (ent.type === 'mention') {
        mentionedUsers.push(ent.data.mention);
      }
    }

    return mentionedUsers;
  }, [JSON.stringify(editorContent)]);

  useEffect(() => {
    setMentionedUsers(extractMentions());
  }, [extractMentions]);

  const handleSetEditorValue = (editorState: any) => {
    setValue('editorContent', editorState);
  };

  const onSubmit = async (values: any) => {
    mutate({
      variables: {
        data: {
          content: convertDraftStateToString(values.editorContent),
          // userId
          // id: "1",
          taskId: taskId,
          usersIDToNotify: mentionedUsers.map((user) => user.id),
        },
      },
      update(cache, { data }) {
        createInCacheWithVars(
          cache,
          data?.createComment,
          { taskId },
          CommentsByTaskIdDocument,
          'commentsByTaskId',
        );
      },
      onCompleted: () => {
        reset();
      },
    });
  };

  const hasText = editorContent?.getCurrentContent().hasText();

  return (
    <Wrapper>
      <form onSubmit={handleSubmit(onSubmit)} style={{ marginTop: 15 }}>
        <FormLabel error={!!errors?.editorContent}>
          Write a comment {!!errors?.editorContent && '(Field cannot empty)'}
        </FormLabel>

        <DraftRichText
          ariaLabel="create-comment"
          draftState={editorContent}
          setDraftState={handleSetEditorValue}
        />

        <small>
          {mentionedUsers.length
            ? `${mentionedUsers.map((u) => u.name).join(', ')} will be notified in this comment`
            : 'No one will be notified on this comment. Use @ to mention users'}
        </small>
        <p className="Error">{errors?.editorContent && 'Enter valid content'}</p>
        {isSubmitting && <LinearProgress />}

        <LoadingButton
          loading={loading}
          disabled={isSubmitting || !hasText}
          color="primary"
          role={'submit-btn'}
          variant="contained"
          type="submit"
          sx={{ mt: 2, display: 'block', ml: 'auto' }}
        >
          Submit
        </LoadingButton>
      </form>
    </Wrapper>
  );
};
