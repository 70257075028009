import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import { IconButton } from '@mui/material';
import { Box } from '@mui/system';
import React from 'react';
import { useFieldArray } from 'react-hook-form';
import { ControlledTextField } from '../ControlledTextField/ControlledTextField';

interface InputArrayProps {
  control: any;
  register: any;
  name: string;
  label: string;
  required?: boolean;
}

export const InputArray: React.FC<InputArrayProps> = ({
  control,
  register,
  name,
  label,
  required,
}) => {
  const { fields, append, remove } = useFieldArray({
    control,
    name: name,
  });

  return (
    <div>
      {fields.map((url, index) => {
        return (
          <Box key={index} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <Box sx={{ flex: '1', mb: 2 }}>
              <ControlledTextField
                label={label}
                name={`${name}[${index}].url`}
                control={control}
                required={required && index === 0}
              />
            </Box>

            <IconButton
              sx={{ ml: 1 }}
              onClick={() => {
                if (index === 0) {
                  append({ url: '' });
                } else {
                  remove(index);
                }
              }}
              aria-label="add or remove floor plan images"
              color="primary"
            >
              {index === 0 ? <AddIcon /> : <RemoveIcon />}
            </IconButton>
          </Box>
        );
      })}
    </div>
  );
};
