import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { NumberParam, useQueryParam } from 'use-query-params';

import { yupResolver } from '@hookform/resolvers/yup';
import { LoadingButton } from '@mui/lab';
import { Box, Button, LinearProgress, MenuItem, TextField } from '@mui/material';
import Typography from '@mui/material/Typography';

import {
  TasksPaginatedDocument,
  TaskQuery,
  useCreateTaskMutation,
  useUpdateTaskMutation,
} from '../../../generated/graphql';
import { statuses, TaskTypes } from '../../../utils/constants';
import { convertDraftStateToString, getInitialDraftValue } from '../../../utils/helper';
import { ControlledDatePicker } from '../ControlledDatePicker/ControlledDatePicker';
import { ControlledTextField } from '../ControlledTextField/ControlledTextField';
import { DraftRichText } from '../DraftRichText/DraftRichText';
import { initialPayload, initialPayloadNewTask } from './helpers/initialPayload';
import { getSchema } from './helpers/validations';
import { PropUpTaskForm } from './PropUpTaskForm/PropUpTaskForm';
import { TouchUpTaskForm } from './TouchUpTaskForm/TouchUpTaskForm';
import { ExtraTaskForm } from './ExtraTaskForm/ExtraTaskForm';
import { MaterialTaskForm } from './MaterialTaskForm/MaterialTaskForm';
import { TaskTypeSelect } from './TaskTypeSelect/TaskTypeSelect';

interface CreateUpdateTaskFormProps {
  task?: TaskQuery['task'];
  toggleEditMode?: () => void;
  closeCreateModal?: () => void;
}

export const CreateUpdateTaskForm: React.FC<CreateUpdateTaskFormProps> = ({
  task,
  toggleEditMode,
  closeCreateModal,
}) => {
  const [taskTypeId, setTaskTypeId] = useState<number | null>(task?.taskTypeId || null);
  const [dueDate, setDueDate] = useState(task?.dueDate ? new Date(task.dueDate) : null);
  const [draftState, setDraftState] = useState(getInitialDraftValue(task?.content || ''));

  const [createTask, { loading: loadingCreate }] = useCreateTaskMutation();
  const [updateTask, { loading: loadingUpdate }] = useUpdateTaskMutation();

  const [, setModalTaskId] = useQueryParam('task-id', NumberParam);

  const schema = getSchema(taskTypeId);

  const {
    control,
    handleSubmit,
    formState: { isSubmitting, errors },
    watch,
    register,
  } = useForm({
    mode: 'all',
    resolver: yupResolver(schema),
    defaultValues: {
      title: task?.title || '',
      content: draftState,
      taskTypeId: task?.taskTypeId || taskTypeId,
      status: task?.status || 'Pending',
      ...(task ? initialPayload(task.payload, task.taskTypeId) : {}),
      ...(!task && initialPayloadNewTask()),
    },
  });

  const onSubmit = async (values: any) => {
    let payload = {};

    if (taskTypeId === TaskTypes['Prop']) {
      payload = {
        productTaxonomy: values.productTaxonomy,
        havenlyAssetId: values.havenlyAssetId,
        sourceUrl: values.sourceUrl,
        similarPropUrl: values.similarPropUrl,
        requesterId: values.requesterId,
        kustomerId: values.kustomerId,
        custom: values.custom,
        vendorVariantImageUrl: values.vendorVariantImageUrl,
        vendorVariantTitle: values.vendorVariantTitle,
        baseAssetHash: values.baseAssetHash,
        assetUrl: values.assetUrl,
        assetMetadata: values.assetMetadata,
      };
    }

    if (taskTypeId === TaskTypes['Touch Up']) {
      payload = {
        renderRequestId: values.renderRequestId,
        roomProfileImages: values.roomProfileImages
          .map((roomProfileImage: { url: string }) => roomProfileImage.url)
          .join(','),
        approvedQARenders: values.approvedQARenders
          .map((approvedQARender: { url: string }) => approvedQARender.url)
          .join(','),
        latestPublishedConceptBoard: values.latestPublishedConceptBoard,
        custom: values.custom,
        renderDataPayload: values.renderDataPayload,
      };
    }

    if (taskTypeId === TaskTypes.Extra) {
      payload = {
        havenlyAssetId: values.havenlyAssetId,
        requesterId: values.requesterId,
        custom: values.custom,
        categoryId: values.categoryId,
        creatorCategoryId: values.creatorCategoryId,
        extraTitle: values.extraTitle,
        baseAssetHash: values.baseAssetHash,
        assetUrl: values.assetUrl,
        thumbnailUrl: values.thumbnailUrl,
        assetMetadata: values.assetMetadata,
      };
    }

    if (taskTypeId === TaskTypes.Material) {
      payload = {
        havenlyAssetId: values.havenlyAssetId,
        requesterId: values.requesterId,
        custom: values.custom,
        categoryId: values.categoryId,
        materialTitle: values.materialTitle,
        assetUrl: values.assetUrl,
        thumbnailUrl: values.thumbnailUrl,
        assetMetadata: values.assetMetadata,
      };
    }

    const variables = {
      ...(task && { id: task.id }),
      taskTypeId: values.taskTypeId,
      title: values.title,
      content: convertDraftStateToString(draftState),
      payload: payload,
      dueDate: values.dueDate,
      status: values.status,
    };

    try {
      if (task) {
        await updateTask({
          variables: {
            data: {
              ...variables,
              id: task.id,
            },
          },
          onCompleted: () => {
            if (task && typeof toggleEditMode === 'function') {
              toggleEditMode();
            }
          },
        });
      } else {
        await createTask({
          variables: {
            data: variables,
          },
          refetchQueries: [TasksPaginatedDocument],
          onCompleted: (data) => {
            if (typeof closeCreateModal === 'function') {
              closeCreateModal();
              setModalTaskId(data.createTask.id);
            }
          },
        });
      }
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error({ e });
    }
  };

  let taskForm;

  if (taskTypeId === TaskTypes['Prop']) {
    taskForm = <PropUpTaskForm watch={watch} control={control} />;
  }

  if (taskTypeId === TaskTypes['Touch Up']) {
    taskForm = <TouchUpTaskForm register={register} watch={watch} control={control} />;
  }

  if (taskTypeId === TaskTypes.Extra) {
    taskForm = <ExtraTaskForm watch={watch} control={control} />;
  }

  if (taskTypeId === TaskTypes.Material) {
    taskForm = <MaterialTaskForm watch={watch} control={control} />;
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Typography variant="h5" component="h2" sx={{ mb: 4 }}>
        {task?.id ? 'Update Task' : 'Create Task'}
      </Typography>
      <ControlledTextField
        control={control}
        name="title"
        label="Title"
        error={!!errors.title}
        required
        variant="outlined"
        fullWidth
      />
      <Box
        sx={{
          marginTop: 0,
          display: 'grid',
          gridTemplateColumns: { xs: '1fr', md: '1fr 1fr' },
          gridGap: '15px',
        }}
      >
        <TextField
          select
          fullWidth
          sx={{ mt: 2 }}
          defaultValue="Pending"
          label="Status"
          inputProps={register('status')}
          error={errors.status}
          helperText={errors.status?.message}
        >
          {statuses.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </TextField>

        <ControlledDatePicker
          control={control}
          name="dueDate"
          rules={{ required: true }}
          value={dueDate}
          label="Due date"
          required
          onChange={setDueDate}
        />
      </Box>
      <Box sx={{ my: 2 }}>
        <DraftRichText setDraftState={setDraftState} draftState={draftState} />
      </Box>

      <TaskTypeSelect
        register={register}
        errors={errors}
        taskTypeId={taskTypeId}
        setTaskTypeId={setTaskTypeId}
      />

      {taskForm}

      {isSubmitting && <LinearProgress />}
      <br />
      <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 2 }}>
        {task?.id && (
          <Button sx={{ mr: 1 }} variant="outlined" color="primary" onClick={toggleEditMode}>
            Cancel
          </Button>
        )}
        <LoadingButton
          loading={loadingCreate || loadingUpdate}
          // disabled={isSubmitting || !isValid}
          variant="contained"
          color="primary"
          type="submit"
        >
          {task ? 'Update' : 'Create'}
        </LoadingButton>
      </Box>
    </form>
  );
};
