import { Box } from '@mui/material';
import React from 'react';
import { ControlledTextField } from '../../ControlledTextField/ControlledTextField';

interface ExtraTaskFormProps {
  control: any;
  watch: any;
}

export const ExtraTaskForm: React.FC<ExtraTaskFormProps> = ({ control }) => {
  return (
    <Box
      sx={{
        marginTop: '15px',
        display: 'grid',
        gridTemplateColumns: { xs: '1fr', md: '1fr 1fr' },
        gridGap: '15px',
      }}
    >
      <ControlledTextField label="Havenly Asset ID" name="havenlyAssetId" control={control} required />
      <ControlledTextField label="Requester ID" name="requesterId" control={control} required />
      <ControlledTextField label="Category ID" name="categoryId" control={control} required />
      <ControlledTextField label="Creator Category ID" name="creatorCategoryId" control={control} required />
      <ControlledTextField label="Extra Title" name="extraTitle" control={control} required/>
      <ControlledTextField label="Base Asset Hash" name="baseAssetHash" control={control} />
      <ControlledTextField label="Asset URL" name="assetUrl" control={control} />
      <ControlledTextField label="Thumbnail URL" name="thumbnailUrl" control={control} />
      <ControlledTextField label="Asset Metadata" name="assetMetadata" control={control} />
    </Box>
  );
};
