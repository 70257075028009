import { Paper } from '@mui/material';
import Typography from '@mui/material/Typography';
import React, { useState } from 'react';
import { formatFullDate } from '../../../../utils/date';
import { getInitialDraftValue } from '../../../../utils/helper';
import { DraftRichText } from '../../../common/DraftRichText/DraftRichText';

interface CommentProps {
  comment: any;
}

export const Comment: React.FC<CommentProps> = ({ comment }) => {
  const [draftState, setDraftState] = useState(() => getInitialDraftValue(comment.content));

  return (
    <Paper style={{ padding: '15px', marginTop: 20 }}>
      <h4 style={{ margin: 0, textAlign: 'left' }}>{comment.user.name}</h4>
      <DraftRichText draftState={draftState} readOnly={true} setDraftState={setDraftState} />
      <Typography sx={{ color: 'text.disabled' }} variant="caption" display="block">
        { formatFullDate(comment.createdAt as string) }
      </Typography>
    </Paper>
  );
};
