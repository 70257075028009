import React, { useContext, useState } from 'react';
import { Alert, Box, Button, Modal, TextField, Typography } from '@mui/material';
import {
  TaskQuery,
  TaskStatuses,
  useBlockTaskMutation as useBlockTask,
  useUnblockTaskMutation as useUnblockTask,
  CommentsByTaskIdDocument,
  TaskDetailsDocument
} from '../../../../generated/graphql';
import { SnackbarContext } from '../../../../contexts/Snackbar/SnackbarProvider';

const modalStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
};

const commentStyle = {
  width: '100%',
}

interface BlockModalProps {
  open: boolean;
  handleClose: () => void;
  task: TaskQuery['task'];
}

export const BlockModal: React.FC<BlockModalProps> = ({
  open, handleClose, task
}) => {
  const [blockTask, {loading: loadingBlock, error: errorBlock }] = useBlockTask();
  const [unblockTask, {loading: loadingUnblock, error: errorUnblock }] = useUnblockTask();
  const [comment, setComment] = useState<string>('');
  const { snackThis } = useContext(SnackbarContext)
  
  const handleTextFieldChange = (event: React.ChangeEvent<HTMLTextAreaElement>) =>{
    setComment(event.target.value);
  }
  
  const canSubmit = !!comment.length && !loadingBlock && !loadingUnblock;
  
  const submit = async () => {
    if (task.status === TaskStatuses.Blocked) {
      await unblockTask({
        refetchQueries: [CommentsByTaskIdDocument, TaskDetailsDocument],
        variables: {
          taskId: task.id.toString(),
          comment
        }
      });
      snackThis('Task unblocked successfully.');
    } else {
      await blockTask({
        refetchQueries: [CommentsByTaskIdDocument, TaskDetailsDocument],
        variables: {
          taskId: task.id.toString(),
          comment
        }
      });
      snackThis('Task blocked successfully.');
    }
    setComment('');
    handleClose();
  }
  
  const isTaskBlocked = task.status === TaskStatuses.Blocked;

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={modalStyle}>
        <Typography id="modal-modal-title" variant="h6" component="h2">
        { isTaskBlocked ? 'Unblocking' : 'Blocking' } 
        {' '}task #{task.id}
        </Typography>

        <Typography id="modal-modal-description" sx={{ mt: 2 }}>
          Please state the reason for the{' '}
          { isTaskBlocked ? 'unblock' : 'block' }{' '}
          (mandatory):
        </Typography>
        
        <TextField
          multiline
          style={commentStyle}
          onChange={handleTextFieldChange}
          rows={3}
          sx={{ mt: 2}}
        />
        
        { (errorBlock || errorUnblock) && (
          <Alert sx={{ mt: 2 }} severity="error">Error updating task status</Alert>
        )}
        
        <Button
          disabled={!canSubmit}
          variant="contained"
          style={{ marginTop: '15px'}}
          onClick={submit}
        >
          submit
        </Button>
        
      </Box>
    </Modal>
  )
}
