import { Box } from '@mui/material';
import React from 'react';
import { TaskQuery, useAssignOwnersToTaskMutation } from '../../../../generated/graphql';
import { Owner } from './Owner/Owner';
import { SearchUserInput } from './searchUserInput/SearchUserInput';

interface TaskOwnersProps extends TaskQuery {}

export const TaskOwners: React.FC<TaskOwnersProps> = ({ task }) => {
  const [mutate] = useAssignOwnersToTaskMutation();

  const handleSelectOwner = (owner: any) => {
    mutate({
      variables: {
        data: {
          taskId: task.id,
          userIds: [owner.id],
        },
      },
    });
  };

  const owners = task.owners;

  const taskOwnerIds = owners ? owners.map((owner) => owner.id) : [];

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: { xs: 'flex-start', md: 'flex-end' },
        maxWidth: '320px',
      }}
    >
      <SearchUserInput onSelectOwner={handleSelectOwner} taskOwnerIds={taskOwnerIds} />
      <Box
        sx={{
          mt: 2,
          display: 'flex',
          flexWrap: 'wrap',
          gap: '15px',
          justifyContent: { xs: 'flex-start', md: 'flex-end' },
        }}
      >
        {owners &&
          owners.map((owner) => (
            <Owner key={owner.id} id={owner.id} taskId={task.id} ownerName={owner.name} />
          ))}
      </Box>
    </Box>
  );
};
