import React, { useCallback, useState } from 'react';

import { LoadingButton } from '@mui/lab';
import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';

import { useUsersPaginatedQuery } from '../../generated/graphql';
import { LoadingScreen } from '../common/LoadingScreen/LoadingScreen';
import { IHeadCell, TableHeadCell } from '../common/TableHeadCell/TableHeadCell';
import { Row } from './Row/Row';
import { TableToolbar } from './TableToolbar/TableToolbar';

export interface FieldsToOrder {
  id: number;
  email: string;
  name: string;
  groups: string[];
}

const headCells: readonly IHeadCell[] = [
  {
    id: 'id',
    label: 'ID',
    allowsOrder: false,
  },
  {
    id: 'email',
    label: 'Email',
    allowsOrder: false,
  },
  {
    id: 'name',
    label: 'Name',
    allowsOrder: false,
  },
  // Vd. <Row> component
  // {
  //   id: 'groups',
  //   label: 'Groups',
  //   allowsOrder: false,
  // },
];

interface UserTableProps {}

export const UserTable: React.FC<UserTableProps> = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [search, setSearch] = useState('');
  const [selected, setSelected] = useState<number[]>([]);

  const {
    loading,
    error,
    data: userData,
    previousData,
    fetchMore,
    variables,
  } = useUsersPaginatedQuery({
    variables: {
      limit: 20,
      search: search,
    },
  });

  const data = userData ? userData : previousData;

  const handleSearchQuery = useCallback((search: string) => {
    setSearch(search);
  }, []);

  const handleSelectRow = (event: any, id: number) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected: number[] = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }

    setSelected(newSelected);
  };

  if (loading && !previousData) {
    return <LoadingScreen />;
  }

  if ((error && !loading) || !data) {
    return <div>There was an error {error?.message}</div>;
  }

  const numSelected = selected.length;
  const rows = data.usersPaginated.items;

  return (
    <Box>
      <Paper sx={{ width: '100%', mb: 4, mt: 2 }}>
        <TableContainer component={Paper} sx={{ my: 3 }}>
          <TableToolbar
            loading={loading}
            numSelected={numSelected}
            handleSearchQuery={handleSearchQuery}
          />
          <Table size="medium" aria-label="collapsible table">
            <TableHead>
              <TableRow>
                <TableCell align="center" />
                {headCells.map((headCell) => (
                  <TableHeadCell key={headCell.id} headCell={headCell} />
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map((user) => (
                <Row key={user.id} row={user} selected={selected} onSelectRow={handleSelectRow} />
              ))}
            </TableBody>
          </Table>
        </TableContainer>

        {data.usersPaginated.paginationToken && (
          <Box sx={{ display: 'flex', justifyContent: 'center', py: 3 }}>
            <LoadingButton
              variant="contained"
              loading={isLoading}
              onClick={async () => {
                setIsLoading(true);
                await fetchMore({
                  variables: {
                    limit: variables?.limit,
                    paginationToken: data.usersPaginated.paginationToken,
                  },
                });
                setIsLoading(false);
              }}
            >
              Load More Users
            </LoadingButton>
          </Box>
        )}
      </Paper>
    </Box>
  );
};
