import { CircularProgress } from '@mui/material';
import React from 'react';
import { useCommentsByTaskIdQuery } from '../../../generated/graphql';
import { Comment } from './Comment/Comment';

interface CommentsProps {
  taskId: number;
}

export const Comments: React.FC<CommentsProps> = ({ taskId }) => {
  const { data, loading, error } = useCommentsByTaskIdQuery({
    variables: {
      taskId,
    },
  });

  if (loading) {
    return <CircularProgress id="loading" />;
  }

  if (error) {
    return <div>Error loading comments</div>;
  }

  if (data && data.commentsByTaskId!.length === 0) {
    return <div>No comments yet</div>;
  }

  return (
    <div>
      {data!.commentsByTaskId!.map((comment) => {
        return <Comment key={comment.id} comment={comment} />;
      })}
    </div>
  );
};
