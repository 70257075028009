import { ApolloCache, DocumentNode } from "@apollo/client";

export const createInCacheWithVars = (
  cache: ApolloCache<any>,
  toCreate: any,
  vars: any,
  query: DocumentNode,
  entityName: string
) => {
  const existingEntities: any = cache.readQuery({
    query: query,
    variables: vars,
  });

  if (existingEntities && toCreate) {
    cache.writeQuery({
      query: query,
      variables: vars,
      data: {
        [entityName]: [...existingEntities[entityName], toCreate],
      },
    });
  }
};
