import CloseIcon from '@mui/icons-material/Close';
import { Chip } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import React from 'react';
import { useUnassignOwnersFromTaskMutation } from '../../../../../generated/graphql';

interface OwnerProps {
  id: number;
  taskId: number;
  ownerName: string;
}

export const Owner: React.FC<OwnerProps> = ({ id, taskId, ownerName }) => {
  const [mutate, { loading }] = useUnassignOwnersFromTaskMutation();

  return (
    <Chip
      label={ownerName}
      deleteIcon={loading ? <CircularProgress size={15} /> : <CloseIcon />}
      onDelete={() =>
        mutate({
          variables: {
            data: {
              taskId: taskId,
              userIds: [id],
            },
          },
        })
      }
    />
  );
};
