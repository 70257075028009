import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import { LoadingButton } from '@mui/lab';
import { Box, FormGroup, FormHelperText, FormLabel, IconButton, Typography } from '@mui/material';
import React, { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { TaskDocument, useCreateSubtaskMutation } from '../../../generated/graphql';
import { convertDraftStateToString, getInitialDraftValue } from '../../../utils/helper';
import { DraftRichText } from '../../common/DraftRichText/DraftRichText';

interface CreateSubtaskFormProps {
  taskId: number;
}

export const CreateSubtaskForm: React.FC<CreateSubtaskFormProps> = ({ taskId }) => {
  const [open, setOpen] = useState(false);
  const [mutate, { loading }] = useCreateSubtaskMutation();

  const {
    control,
    handleSubmit,
    watch,
    setValue,
    formState: { isSubmitting },
  } = useForm({
    mode: 'all',
    defaultValues: {
      content: getInitialDraftValue(''),
    },
  });

  const onSubmit = async (values: any) => {
    try {
      await mutate({
        variables: {
          data: {
            parentTaskId: taskId,
            content: convertDraftStateToString(values.content),
          },
        },
        onCompleted: () => {
          setOpen(false);
        },
        update: (cache, { data }) => {
          const vars = { id: taskId };

          const createdTask = data?.createSubtask;

          const currentTask: any = cache.readQuery({
            query: TaskDocument,
            variables: vars,
          });

          if (currentTask && createdTask) {
            cache.writeQuery({
              query: TaskDocument,
              variables: vars,
              data: {
                task: {
                  ...currentTask.task,
                  subtasks: [...currentTask.task.subtasks, createdTask],
                },
              },
            });
          }
        },
      });
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error({ e });
    }
  };

  const handleSetEditorValue = (editorState: any) => {
    setValue('content', editorState);
  };

  return (
    <Box sx={{ my: 3 }}>
      <Box sx={{ display: 'flex' }}>
        <Typography sx={{ display: 'flex', alignItems: 'center' }} variant="h6">
          Create Subtask
        </Typography>
        <IconButton
          sx={{ ml: 1 }}
          onClick={() => setOpen(!open)}
          aria-label="add subtask"
          color="primary"
        >
          {open ? <RemoveIcon /> : <AddIcon />}
        </IconButton>
      </Box>
      {open && (
        <form onSubmit={handleSubmit(onSubmit)}>
          <Controller
            control={control}
            name="content"
            render={({ field, fieldState: { error } }) => (
              <FormGroup sx={{ mt: 2 }}>
                <FormLabel error={!!error?.message}>
                  Content (The rest of the data will be copied from the parent task)
                </FormLabel>
                <Box sx={{ my: 2 }}>
                  <DraftRichText
                    draftState={watch('content')}
                    setDraftState={handleSetEditorValue}
                  />
                </Box>
                <FormHelperText error={!!error?.message}>{error?.message}</FormHelperText>
              </FormGroup>
            )}
          />
          <LoadingButton
            loading={loading}
            disabled={isSubmitting}
            variant="contained"
            color="primary"
            type="submit"
          >
            Submit
          </LoadingButton>
        </form>
      )}
    </Box>
  );
};
