import React from 'react';
import { CheckCircle } from '@mui/icons-material';
import { ActionMenuItemProps } from './MenuInterface';
import { useRevisionApproveTaskMutation } from '../../../../../generated/graphql';
import { BaseMenuItem, useBaseMenuAction, refetchQueries } from '../BaseMenuItem';

export const ApproveMenuItem: React.FC<ActionMenuItemProps> = ({
  handleMenuClick, task 
}) => {
  const [approveTask, { loading }] = useRevisionApproveTaskMutation();
  const { action } = useBaseMenuAction(
    () => approveTask({
      refetchQueries,
      variables: {
        taskId: task.id.toString()
      }
    }),
    handleMenuClick,
    'Task approved successfully',
    'Error approving task'
  )

  return (
    <BaseMenuItem
      menuClick={action}
      loading={loading}
      icon={<CheckCircle fontSize="small" />}
      text="Approve"
    />
  );
}
