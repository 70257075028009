import React from 'react';
import Field from './Field';
import { TaskDetailsQuery } from '../../../../generated/graphql';


type LiDARLabelText = 'Yes' | 'No' | 'N/A';

export default function RoomScanField({ task }: any) {
  return (
    <Field title={'LiDAR Scanned Room?'} text={isScannedRoom(task)} />
  )
}


function isScannedRoom(task: TaskDetailsQuery['task']): LiDARLabelText {
  if (task.parentTaskId) {
    if (!task.parentTask) return 'N/A';
    return task.parentTask?.payload?.roomScanFile ? 'Yes' : 'No';
  }

  if (!task.payload) return 'N/A';

  return task.payload.roomScanFile ? 'Yes' : 'No';
}

