import React from 'react';
import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid';
import Link from '@mui/material/Link';
import { GridCellExpand } from './GridCellExpand/GridCellExpand';
import { TaskTypes, threeDVendors } from '../../utils/constants';
import { useNavigateToTask } from '../../hooks/useNavigateToTask';
import { useEZStore } from '../../store/store';
import { isHavenlyAdminOrUser } from '../../utils/groups';

export function useTaskTableColumns(): GridColDef[] {
  const navigate = useNavigateToTask();
  const { user } = useEZStore();
  const canSeeVendors = isHavenlyAdminOrUser(user);

  const columns: GridColDef[] = [
    {
      field: 'id',
      headerName: 'ID',
      minWidth: 40,
      maxWidth: 80,
      sortable: true,
      flex: 1,
      renderCell: (row: any) => {
        return (
          <Link
            component="button"
            variant="body2"
            onClick={() => {navigate(row.id)}}
          >
            #{row.id}
          </Link>
        );
      },
    },
    {
      field: 'parentTaskId',
      headerName: 'Parent',
      minWidth: 40,
      maxWidth: 80,
      sortable: true,
      flex: 1,
      renderCell: (row: any) => {
        if (!row.row.parentTaskId) {
          return 'None';
        }
        return (
          <Link
            component="button"
            variant="body2"
            onClick={() => {navigate(row.row.parentTaskId)}}
          >
            #{row.row.parentTaskId}
          </Link>
        );
      },
    },
    {
      field: 'title',
      headerName: 'Title',
      minWidth: 50,
      sortable: true,
      flex: 1,
      renderCell: (params) => {
        return (
          <GridCellExpand 
            value={params.value}
            width={params.colDef.computedWidth}
            href={`/tasks/${params.row.id}`}
          />
        );
      },
    },
    { 
      field: 'taskTypeId', 
      headerName: 'Type', 
      minWidth: 50, 
      maxWidth: 120, 
      sortable: true, 
      flex: 1,
      valueGetter: (row: any) =>
      {
        return TaskTypes[row.row.taskTypeId];
      }
    },
    { 
      field: 'taskSubtype', 
      headerName: 'Sub-Type', 
      minWidth: 50, 
      maxWidth: 120, 
      sortable: false, 
      flex: 1,
      valueGetter: (row: any) =>
      {
        return row.row.parentTaskId ? 'Revision' : 'Initial';
      }
    },
    // conditional vendor column is added in this position further in the code
    {
      field: 'content',
      headerName: 'Content',
      minWidth: 50,
      sortable: false,
      flex: 1,
      renderCell: renderCellExpand,
    },
    { field: 'status', headerName: 'Status', minWidth: 50, maxWidth: 120, sortable: true, flex: 1 },
    {
      field: 'commentCount', 
      headerName: 'Comments', 
      flex: 1,
      maxWidth: 120,
      sortable: true,
      sortComparator: (a, b) => (a as number) - (b as number),
      renderCell: (params) => params.row.commentCount ?? '0',
      valueGetter: (params) =>
      {
        return Number(params.row.commentCount) ?? 0;
      }
    },
    {
      field: 'similarPropUrl',
      headerName: 'Similar Prop URL',
      minWidth: 50,
      maxWidth: 140,
      sortable: false,
      flex: 1,

      renderCell: (row: any) => {
        if (!row.row.payload?.similarPropUrl) {
          return 'None';
        }
        return (
          <Link href={row.row.payload?.similarPropUrl} rel="noreferrer" target="_blank">
            Similar Prop URL
          </Link>
        );
      },
    },
    {
      field: 'sourceUrl',
      headerName: 'Source URL',
      minWidth: 50,
      maxWidth: 120,
      sortable: false,
      flex: 1,

      renderCell: (row: any) => {
        if (!row.row.payload?.sourceUrl) {
          return 'None';
        }
        return (
          <Link href={row.row.payload?.sourceUrl} rel="noreferrer" target="_blank">
            Source URL
          </Link>
        );
      },
    },
    {
      field: 'vendorURL',
      headerName: 'Vendor URL',
      minWidth: 50,
      maxWidth: 120,
      sortable: false,
      flex: 1,

      renderCell: (row: any) => {
        if (!row.row.payload?.vendorVariantWeblink) {
          return 'None';
        }
        return (
          <Link href={row.row.payload?.vendorVariantWeblink} rel="noreferrer" target="_blank">
            Vendor URL
          </Link>
        );
      },
    },
    {
      field: 'owners',
      headerName: 'Owners',
      minWidth: 100,
      sortable: false,
      flex: 1,
      renderCell: renderCellExpand,
    },
    {
      field: 'createdAt',
      headerName: 'Created Date',
      minWidth: 50,
      maxWidth: 120,
      sortable: true,
      flex: 1,
    },
    {
      field: 'dueDate',
      headerName: 'Due Date',
      minWidth: 50,
      maxWidth: 120,
      sortable: true,
      flex: 1,
    },
  ];

  // Add vendor after task sub-type only for Havenly user/admin
  const vendorColumnPosition = columns.findIndex(
    (column: GridColDef) => column.field === 'taskSubtype'
  ) + 1;
  if (canSeeVendors) {
    columns.splice(vendorColumnPosition, 0, { 
      field: 'tenantId', 
      headerName: 'Vendor', 
      minWidth: 50, 
      maxWidth: 120, 
      sortable: true, 
      flex: 1,
      valueGetter: ({ row }: any) =>
      {
        return threeDVendors[row.vendor.id] ?? threeDVendors[threeDVendors.Unknown];
      }
    });
  }

  return columns;
}

function renderCellExpand({ value, colDef }: GridRenderCellParams<string>) {
  return <GridCellExpand
    value={value || ''}
    width={colDef.computedWidth}
  />;
}
