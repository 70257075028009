import React, { useEffect, useState } from 'react';

import { alpha, Box, Button, LinearProgress, Typography } from '@mui/material';
import TextField from '@mui/material/TextField';
import Toolbar from '@mui/material/Toolbar';
import { CreateUserModal } from '../../../modals/CreateUserModal';

interface TableToolbarProps {
  handleSearchQuery: (string: string) => void;
  loading: boolean;
  numSelected: number;
}

export const TableToolbar: React.FC<TableToolbarProps> = ({
  handleSearchQuery,
  loading,
  numSelected,
}) => {
  const [search, setSearch] = useState('');
  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    if (search === '') {
      handleSearchQuery('');
    }
  }, [search, handleSearchQuery]);

  return (
    <Toolbar
      sx={{
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 },
        ...(numSelected > 0 && {
          bgcolor: (theme) =>
            alpha(theme.palette.primary.main, theme.palette.action.activatedOpacity),
        }),
      }}
    >
      <Box
        sx={{
          mt: 1,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'baseline',
          justifyContent: 'space-between',
          width: numSelected > 0 ? '100%' : 'fit-content',
        }}
      >
        <Box sx={{ display: 'flex', width: '100%' }}>
          {numSelected > 0 ? (
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                width: '100%',
                gap: '12px',
              }}
            >
              <Typography>{numSelected} selected</Typography>
            </Box>
          ) : (
            <form
              onSubmit={(event) => {
                event.preventDefault();
                handleSearchQuery(search);
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  mr: 3,
                }}
              >
                <TextField
                  sx={{ width: '200px', maxWidth: '100%' }}
                  id="search"
                  label="Search"
                  variant="standard"
                  value={search}
                  onChange={(event) => setSearch(event.target.value)}
                />
                <Button sx={{ ml: 2 }} type="submit" variant="outlined">
                  Search
                </Button>
              </Box>
            </form>
          )}

          <Button variant="contained" onClick={() => setIsModalOpen(true)}>
            Create User
          </Button>

          <CreateUserModal onClose={() => setIsModalOpen(false)} isModalOpen={isModalOpen} />
        </Box>

        <Box sx={{ width: '100%', mt: 2 }}>{loading && <LinearProgress />}</Box>
      </Box>
    </Toolbar>
  );
};
