import React from 'react';
import { ActionMenuItemProps } from './MenuInterface';
import { Update } from '@mui/icons-material';

import { useTriggerRoomShellRoomImagesUpdateMutation } from '../../../../../generated/graphql';
import { BaseMenuItem, refetchQueries, useBaseMenuAction } from '../BaseMenuItem';

export const UpdateInfoMenuItem: React.FC<ActionMenuItemProps> = ({
  handleMenuClick,
  task
}) => {
  const [triggerRoomShellRoomImagesUpdate, {loading}] = useTriggerRoomShellRoomImagesUpdateMutation();

  const { action } = useBaseMenuAction(
    () => triggerRoomShellRoomImagesUpdate({
      refetchQueries,
      variables: {
        roomShellRequestId: task.payload.roomShellRequestId
      }
    }),
    handleMenuClick,
    'Triggered SQS message to update room images successfully. Wait a few moments and refresh the window.',
    'Error triggering SQS message to update room images'
  )
  
  return (
    <BaseMenuItem
      menuClick={action}
      loading={loading}
      icon={<Update fontSize="small" />}
      text="Update"
    />
  );
};
