import React from "react";
import { Header } from "../Header/Header";
import { Router } from "../Router/Router";

interface AppProps {}

export const App: React.FC<AppProps> = () => {
  return (
    <div className='App'>
      <Header />
      <Router />
      {/* Add a footer */}
    </div>
  );
};
