import {
  ApolloClient,
  createHttpLink,
  from,
  InMemoryCache,
  NormalizedCacheObject,
  Observable,
} from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import axios from 'axios';
import { useStore } from '../store/store';

const errorLink = onError(({ graphQLErrors, networkError, operation, forward }) => {
  if (graphQLErrors) {
    for (let err of graphQLErrors) {
      switch (err.extensions.code) {
        case 'TOKEN_EXPIRED_ERROR':
          const data = {
            AuthFlow: 'REFRESH_TOKEN_AUTH',
            AuthParameters: {
              REFRESH_TOKEN: useStore.getState().refreshToken,
            },
            ClientId: process.env.REACT_APP_AWS_COGNITO_CLIENT_ID,
          };
          return new Observable((observer) => {
            axios
              .post(
                `https://cognito-idp.${process.env.REACT_APP_AWS_COGNITO_REGION}.amazonaws.com`,
                data,
                {
                  headers: {
                    'Content-Type': 'application/x-amz-json-1.1',
                    'X-Amz-Target': 'AWSCognitoIdentityProviderService.InitiateAuth',
                  },
                },
              )
              .then((res) => {
                const newAccessToken = res.data.AuthenticationResult.AccessToken;
                const setAccessToken = useStore.getState().setAccessToken;
                setAccessToken(newAccessToken);
                const headers = operation.getContext().headers;
                operation.setContext({
                  headers: {
                    ...headers,
                    authorization: `Bearer ${newAccessToken}`,
                  },
                });
              })
              .then(() => {
                const subscriber = {
                  next: observer.next.bind(observer),
                  error: observer.error.bind(observer),
                  complete: observer.complete.bind(observer),
                };
                // Retry last failed request
                forward(operation).subscribe(subscriber);
              })
              .catch((error) => {
                // No refresh or client token available, we force user to login
                observer.error(error);
              });
          });
      }
    }
  }
});

const authLink = setContext((_, { headers }) => {
  // get the authentication token from local storage if it exists
  const accessToken = useStore.getState().accessToken;

  if (!accessToken) {
    return {
      headers,
    };
  }

  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      authorization: `Bearer ${accessToken}`,
    },
  };
});

const httpLink = createHttpLink({
  uri: process.env.REACT_APP_GRAPHQL,
});

export const cache: InMemoryCache = new InMemoryCache({
  typePolicies: {
    Query: {
      fields: {
        usersPaginated: {
          keyArgs: ['search'],
          merge(existing, incoming) {
            if (!existing) {
              return incoming;
            }
            return {
              ...incoming,
              limit: existing.limit + incoming.offset,
              items: [...existing.items, ...incoming.items],
            };
          },
        },
      },
    },
  },
});

export const client: ApolloClient<NormalizedCacheObject> = new ApolloClient({
  link: from([errorLink, authLink, httpLink]),
  cache: cache,
});
