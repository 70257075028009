import { CognitoGroups, MeQuery } from '../generated/graphql';
import { intersection } from 'lodash';

type Me = MeQuery | undefined;

export function isUserInGroup(data: Me, userGroups: CognitoGroups[] | CognitoGroups): boolean {
  const groups = data?.me?.groups;

  if (Array.isArray(userGroups)) {
    return !!intersection(userGroups, groups).length;
  }
  return groups?.includes(userGroups) || false;
}

export const isHavenlyAdmin = (data: Me) => isUserInGroup(data, CognitoGroups.HavenlyAdmin);

export const isAdmin = (data: Me) =>
  isUserInGroup(data, [CognitoGroups.TenantAdmin, CognitoGroups.HavenlyAdmin]);

export const isHavenlyAdminOrUser = (data: Me) =>
  isUserInGroup(data, [CognitoGroups.HavenlyAdmin, CognitoGroups.HavenlyUser]);

export const isUserInAnyGroup = (data: Me) => !!data?.me?.groups?.length;
