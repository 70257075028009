import React, { ReactNode } from 'react';
import { Box, Link } from "@mui/material";

interface Props {
  imageURLs: string;
}

export const ImageLinks = ({ imageURLs }: Props) => (
  <Box>
    {imageURLs.split(',').map<ReactNode>((url, i) => (
        <Link
          rel="noreferrer"
          key={url}
          href={url || '#'}
          target="_blank"
        >
          Image {i + 1}{' '}
        </Link>
      )).reduce((prev, curr) => [prev, ', ', curr])
    }
  </Box>
);
