import { useState } from 'react';

import CloseIcon from '@mui/icons-material/Close';
import { LoadingButton } from '@mui/lab';
import {
    FormControl, Grid, IconButton, InputLabel, MenuItem, Select, SelectChangeEvent, Typography
} from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';

import { TaskStatuses, useMassAssignStatusToTasksMutation } from '../../../../generated/graphql';
import { statuses } from '../../../../utils/constants';

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 540,
  maxWidth: '100%',
  bgcolor: 'background.paper',
  border: '2px solid grey',
  boxShadow: 24,
  p: 5,
  height: { xs: '100vh', md: '50vh' },
  overflow: 'auto',
};

interface AssignStatusToTasksProps {
  selectedTaskIds: number[];
  onResetSelectedTaskIds: () => void;
}

export const AssignStatusToTasks: React.FC<AssignStatusToTasksProps> = ({
  selectedTaskIds,
  onResetSelectedTaskIds,
}) => {
  const [open, setOpen] = useState(false);
  const [status, setStatus] = useState<TaskStatuses | string>('');

  const [mutate, { loading, error }] = useMassAssignStatusToTasksMutation();

  const handleModal = (): void => {
    setOpen(!open);
  };

  const handleChange = (event: SelectChangeEvent) => {
    setStatus(event.target.value as TaskStatuses);
  };

  if (error) {
    return <div>Sorry there was an error: {`${error}`}</div>;
  }

  return (
    <div>
      <Grid container justifyContent="flex-end">
        <Box display="flex" justifyContent="flex-end">
          <Button onClick={handleModal} variant="outlined">
            Assign Status
          </Button>
        </Box>
      </Grid>
      <Modal
        open={open}
        onClose={() => {
          handleModal();
          onResetSelectedTaskIds();
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <IconButton
            sx={{ position: 'absolute', top: 4, right: 4 }}
            onClick={() => {
              handleModal();
              onResetSelectedTaskIds();
            }}
            aria-label="close modal"
            color="primary"
          >
            <CloseIcon />
          </IconButton>
          {open && (
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
                height: '100%',
              }}
            >
              <Box>
                <Typography variant="caption" sx={{ mb: 2, display: 'block' }}>
                  *This will override the current status of the selected tasks
                </Typography>
                <FormControl fullWidth>
                  <InputLabel id="status-tasks-change">Status</InputLabel>
                  <Select
                    labelId="status-tasks-change"
                    id="status-tasks-change"
                    value={status}
                    label="Status"
                    onChange={handleChange}
                  >
                    {statuses.map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Box>

              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                  gap: '15px',
                }}
              >
                <Button
                  onClick={() => {
                    handleModal();
                    onResetSelectedTaskIds();
                  }}
                  variant="outlined"
                >
                  Cancel
                </Button>
                <LoadingButton
                  loading={loading}
                  disabled={!status || selectedTaskIds.length === 0}
                  onClick={() => {
                    mutate({
                      variables: {
                        data: {
                          taskIds: selectedTaskIds,
                          status: status as TaskStatuses,
                        },
                      },
                      onCompleted: () => {
                        // closes the modal after the mutation is complete
                        handleModal();
                        onResetSelectedTaskIds();
                      },
                    });
                  }}
                  variant="contained"
                >
                  Save
                </LoadingButton>
              </Box>
            </Box>
          )}
        </Box>
      </Modal>
    </div>
  );
};
