import { Box, ListItemButton, ListItemText, Typography } from '@mui/material';
import List from '@mui/material/List';
import React from 'react';
import { TaskQuery } from '../../../../generated/graphql';
import { getPlainTextAndTruncateFromDraft } from '../../../../utils/helper';
import { useNavigateToTask } from '../../../../hooks/useNavigateToTask';

interface SubtaskListProps {
  subtasks: TaskQuery['task'][];
}

export const SubtaskList: React.FC<SubtaskListProps> = ({ subtasks }) => {
  const navigate = useNavigateToTask();

  return (
    <Box sx={{ my: 4 }}>
      <Typography variant="h6">Subtasks</Typography>
      <List>
        {subtasks.map((subtask) => (
          <ListItemButton key={subtask.id} onClick={() => navigate(subtask.id)}>
            <ListItemText
              primary={
                <div>
                  <Box sx={{ color: 'info.main' }}>#{subtask?.id}</Box>{' '}
                  {getPlainTextAndTruncateFromDraft(subtask?.content)}
                </div>
              }
            />
          </ListItemButton>
        ))}
      </List>
    </Box>
  );
};
