import { ContentState, convertFromRaw, convertToRaw, EditorState } from 'draft-js';

export const truncate = (input: string, stringMaxLength: number): string => {
  return input.length > 5 ? `${input.substring(0, stringMaxLength)}...` : input;
};

export const isQuillEmpty = (value: string) => {
  if (value.replace(/<(.|\n)*?>/g, '').trim().length === 0 && !value.includes('<img')) {
    return true;
  }
  return false;
};

export const cleanText = (input: string) => {
  return input.replace(/<\/?[^>]+(>|$)/g, '');
};

export const cleanAndTruncateText = (input: string, stringMaxLength: number) => {
  return truncate(cleanText(input), stringMaxLength);
};

export const getPlainTextFromDraft = (content: string) => {
  let parsedRaw;
  try {
    const parsed = JSON.parse(content);
    parsedRaw = parsed;
  } catch (e) {
    parsedRaw = content;
  }

  if (typeof parsedRaw === 'object') {
    const plainText = convertFromRaw(parsedRaw).getPlainText('\u0001');
    return cleanAndTruncateText(plainText, 30);
  }

  return content;
};

export const getPlainTextFromDraftAndClean = (content: string) => {
  return cleanText(getPlainTextFromDraft(content));
};

export const getPlainTextAndTruncateFromDraft = (content: string) => {
  return cleanAndTruncateText(getPlainTextFromDraft(content), 30);
};

export const getInitialDraftValue = (content: string) => {
  let parsedRaw;

  try {
    const parsed = JSON.parse(content);
    parsedRaw = parsed;
  } catch (e) {
    parsedRaw = content;
  }

  let editorState;
  if (typeof parsedRaw === 'object') {
    editorState = EditorState.createWithContent(convertFromRaw(parsedRaw));
  } else if (typeof parsedRaw === 'string' && parsedRaw) {
    editorState = EditorState.createWithContent(ContentState.createFromText(parsedRaw));
  } else {
    editorState = EditorState.createEmpty();
  }

  return editorState;
};

export const convertDraftStateToString = (editorState: EditorState) => {
  const contentState = editorState.getCurrentContent();
  const rawContent = convertToRaw(contentState);

  // hack to be able to test draft content
  // draftJS always generates random key
  // Set the key to a fixed value to be able to test draft

  rawContent.blocks.forEach((block: any) => {
    if (block.text === 'My comment dw9aj9S7a$2') {
      block.key = '6nrti';
    }
  });

  return JSON.stringify(rawContent);
};
