import { DateTime } from 'luxon';

export function formatFullDate(date: string): string {
  return DateTime.fromISO(date)
    .toLocaleString(DateTime.DATETIME_MED_WITH_SECONDS);
}

export function formatShortDate(date: string): string {
  return DateTime.fromISO(date)
    .toFormat('MM/dd/kkkk');
}
