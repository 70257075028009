import { Container } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import { Box } from '@mui/material';
import { TaskDetails } from '../../components/TaskDetails/TaskDetails';

const style = {
  paddingTop: '40px'
};

export const TaskPage: React.FC = () => {
  const { taskId } = useParams();
  const navigate = useNavigate();
  
  if (!taskId) navigate(`/dashboard`);

  return (
    <Container maxWidth="lg" style={style}>
      <main>
        <Box style={{ position: 'relative' }}>
          <TaskDetails taskId={taskId!} />
        </Box>
      </main>
    </Container>
  );
};
