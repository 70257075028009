import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { LoadingScreen } from '../../components/common/LoadingScreen/LoadingScreen';
import { useSignOut } from '../../hooks/useSignOut';

export const SignOut: React.FC = () => {
  const signOut = useSignOut();
  const navigate = useNavigate();

  useEffect(() => {
    const signOutAndRedirect = async () => {
      try {
        await signOut();
        navigate('/');
      } catch (err) {
        // eslint-disable-next-line no-console
        console.log(err);
        navigate('/');
      }
    };

    signOutAndRedirect();
  }, []);

  return <LoadingScreen fullScreen />;
};
