import { FormControl, InputLabel, MenuItem, Select, SelectChangeEvent } from '@mui/material';
import React from 'react';
import { useEZStore } from '../../../../store/store';
import { threeDVendors } from '../../../../utils/constants';

export const FilterVendorSelect: React.FC = () => {
  const { filters: { vendorId }, setFilters } = useEZStore();
  const vendorOptions = [
    {
      id: threeDVendors['Havenly'],
      label: 'Havenly'
    },
    {
      id: threeDVendors['S+A'],
      label: 'S+A'
    },
    {
      id: threeDVendors.eSoft,
      label: 'eSoft'
    }
  ]

  const handleChangeType = (event: SelectChangeEvent) => {
    const vendorId = parseInt(event.target.value);
    setFilters({ vendorId });
  };

  return (
    <FormControl variant="standard" sx={{ m: 0, minWidth: 120 }}>
      <InputLabel id="vendor-label">Vendor</InputLabel>
      <Select
        labelId="vendor-label"
        id="vendorId"
        label="Type"
        value={vendorId ? vendorId.toString() : ''}
        onChange={handleChangeType}
      >
        <MenuItem value="">
          <em>View All</em>
        </MenuItem>
        {vendorOptions.map((vendor) => (
          <MenuItem key={vendor.label} value={vendor.id}>
            {`${vendor.label}`}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};
