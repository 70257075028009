import { Checkbox, TableCell, TableRow } from '@mui/material';
import React from 'react';

interface RowProps {
  row: any;
  selected: number[];
  onSelectRow: (event: any, id: number) => void;
  canMassAssign?: boolean;
}

export const Row: React.FC<RowProps> = ({ row, selected, onSelectRow, canMassAssign }) => {
  const isSelected = (id: number) => selected.indexOf(id) !== -1;
  const isItemSelected = isSelected(row.id);

  return (
    <React.Fragment>
      <TableRow hover sx={{ '& > *': { borderBottom: 'unset' } }}>
        {canMassAssign && (
          <TableCell padding="checkbox">
            <Checkbox
              color="primary"
              checked={isItemSelected}
              onChange={(event) => onSelectRow(event, row.id)}
              inputProps={{
                'aria-labelledby': row.name,
              }}
            />
          </TableCell>
        )}

        <TableCell align="center" />
        <TableCell component="th" scope="row" align="center">
          #{row.id}
        </TableCell>
        {/* <TableCell align="center">{row.id}</TableCell> */}
        <TableCell align="center">{row.email}</TableCell>
        <TableCell align="center">{row.name}</TableCell>

        {/* TODO: Change assigned group. Alter this whenever we change the Groups Field Resolver in graph-lunes */}
        {/*<TableCell align="center" sx={{ wordBreak: 'break-word' }}>*/}
        {/*  <AssignGroupAutocomplete userId={row.id} userGroups={row.groups} />*/}
        {/*</TableCell>*/}
      </TableRow>
    </React.Fragment>
  );
};
