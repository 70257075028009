import CloseIcon from '@mui/icons-material/Close';
import { LoadingButton } from '@mui/lab';
import { Chip, Grid, IconButton } from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import { useState } from 'react';
import { useMassAssignOwnersToTasksMutation } from '../../../../generated/graphql';
import { SearchUserInput } from '../../../TaskDetails/Task/TaskOwners/searchUserInput/SearchUserInput';

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 540,
  maxWidth: '100%',
  bgcolor: 'background.paper',
  border: '2px solid grey',
  boxShadow: 24,
  p: 5,
  height: { xs: '100vh', md: '50vh' },
  overflow: 'auto',
};

interface AssignOwnersToTaskProps {
  selectedTaskIds: number[];
  onResetSelectedTaskIds: () => void;
}

export const AssignOwnersToTasks: React.FC<AssignOwnersToTaskProps> = ({
  selectedTaskIds,
  onResetSelectedTaskIds,
}) => {
  const [open, setOpen] = useState(false);
  const [owners, setOwners] = useState<any[]>([]);

  const [mutate, { loading, error }] = useMassAssignOwnersToTasksMutation();

  const handleModal = (): void => {
    setOpen(!open);
  };

  const handleSelectOwner = (owner: any) => {
    setOwners([...owners, owner]);
  };

  const handleDeleteOwner = (owner: any) => {
    setOwners(owners.filter((o) => o.id !== owner.id));
  };

  const taskOwnerIds = owners.length ? owners.map((owner) => owner.id) : [];

  if (error) {
    return <div>Sorry there was an error: {`${error}`}</div>;
  }

  return (
    <div>
      <Grid container justifyContent="flex-end">
        <Box display="flex" justifyContent="flex-end">
          <Button onClick={handleModal} variant="outlined">
            Assign Owners
          </Button>
        </Box>
      </Grid>
      <Modal
        open={open}
        onClose={() => {
          handleModal();
          onResetSelectedTaskIds();
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <IconButton
            sx={{ position: 'absolute', top: 4, right: 4 }}
            onClick={() => {
              handleModal();
              onResetSelectedTaskIds();
            }}
            aria-label="close modal"
            color="primary"
          >
            <CloseIcon />
          </IconButton>
          {open && (
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
                height: '100%',
              }}
            >
              <Box>
                <SearchUserInput onSelectOwner={handleSelectOwner} taskOwnerIds={taskOwnerIds} />

                <Box
                  sx={{
                    mt: 2,
                    display: 'flex',
                    flexWrap: 'wrap',
                    gap: '15px',
                    justifyContent: { xs: 'flex-start', md: 'flex-end' },
                  }}
                >
                  {owners.length > 0 &&
                    owners.map((owner) => (
                      <Chip
                        label={owner.name}
                        deleteIcon={<CloseIcon />}
                        onDelete={() => handleDeleteOwner(owner)}
                      />
                    ))}
                </Box>
              </Box>

              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                  gap: '15px',
                }}
              >
                <Button
                  onClick={() => {
                    handleModal();
                    onResetSelectedTaskIds();
                  }}
                  variant="outlined"
                >
                  Cancel
                </Button>
                <LoadingButton
                  loading={loading}
                  onClick={async () => {
                    await mutate({
                      variables: {
                        data: {
                          taskIds: selectedTaskIds,
                          userIds: taskOwnerIds,
                        },
                      },
                    });
                    // closes the modal after the mutation is complete
                    handleModal();
                    onResetSelectedTaskIds();
                  }}
                  variant="contained"
                >
                  Save
                </LoadingButton>
              </Box>
            </Box>
          )}
        </Box>
      </Modal>
    </div>
  );
};
