import React from 'react';

import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select, { SelectChangeEvent } from '@mui/material/Select';

import { FilterPayloadFieldInput, PayloadFilterStatuses, FilterablePayloadFields } from '../../../../generated/graphql';

interface FilterSimilarPropUrlProps {
  handleFilterPayloadFields: (newFilterPayloadField: FilterPayloadFieldInput) => void;
  clearFilterPayloadField: (payloadField: string) => void;
}

export const FilterSimilarPropUrl: React.FC<FilterSimilarPropUrlProps> = ({
  handleFilterPayloadFields,
  clearFilterPayloadField,
}) => {
  const [similarPropUrlFilterStatus, setSimilarPropUrlFilterStatus] = React.useState<
    PayloadFilterStatuses | ''
  >('');

  const handleChange = (event: SelectChangeEvent) => {
    setSimilarPropUrlFilterStatus(event.target.value as PayloadFilterStatuses | '');

    if (event.target.value === '') {
      clearFilterPayloadField(FilterablePayloadFields.SimilarPropUrl);
    } else {
      handleFilterPayloadFields({
        payloadField: FilterablePayloadFields.SimilarPropUrl,
        filterStatus: event.target.value as PayloadFilterStatuses,
      });
    }
  };
  return (
    <Box sx={{ minWidth: 160 }}>
      <FormControl variant="standard" fullWidth>
        <InputLabel id="similar-prop-url-filter">Similar Prop Url</InputLabel>
        <Select
          labelId="similar-prop-url-filter"
          id="similar-prop-url-select"
          value={similarPropUrlFilterStatus}
          label="Filter Similar Prop Url"
          onChange={handleChange}
        >
          <MenuItem value="">
            <em>View All</em>
          </MenuItem>
          <MenuItem value={PayloadFilterStatuses.Empty}>Empty</MenuItem>
          <MenuItem value={PayloadFilterStatuses.NotEmpty}>Not Empty</MenuItem>
        </Select>
      </FormControl>
    </Box>
  );
};
