import { Box } from '@mui/material';
import React from 'react';
import { ControlledTextField } from '../../ControlledTextField/ControlledTextField';
import { InputArray } from '../../InputArray/InputArray';

interface TouchUpTaskFormProps {
  control: any;
  watch: any;
  register: any;
}

export const TouchUpTaskForm: React.FC<TouchUpTaskFormProps> = ({ control, register }) => {
  return (
    <Box
      sx={{
        marginTop: '15px',
        display: 'grid',
        gridTemplateColumns: { xs: '1fr', md: '1fr 1fr' },
        gridGap: '15px',
      }}
    >
      <ControlledTextField
        label="Render Request ID"
        name="renderRequestId"
        control={control}
        required
      />

      <ControlledTextField
        label="Latest Published Concept Board"
        name="latestPublishedConceptBoard"
        control={control}
        required
      />

      <InputArray
        name="roomProfileImages"
        label="Room Profile Images"
        control={control}
        register={register}
      />

      <InputArray
        name="approvedQARenders"
        label="Approved QA Renders"
        control={control}
        register={register}
        required
      />

      {/* <ControlledTextField label='Custom' name='custom' control={control} /> */}
    </Box>
  );
};
