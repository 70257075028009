import createLinkPlugin from '@draft-js-plugins/anchor';
import '@draft-js-plugins/anchor/lib/plugin.css';
import { BoldButton, ItalicButton, UnderlineButton } from '@draft-js-plugins/buttons';
import Editor from '@draft-js-plugins/editor';
import createInlineToolbarPlugin from '@draft-js-plugins/inline-toolbar';
import '@draft-js-plugins/inline-toolbar/lib/plugin.css';
import createLinkifyPlugin from '@draft-js-plugins/linkify';
import createMentionPlugin from '@draft-js-plugins/mention';
import '@draft-js-plugins/mention/lib/plugin.css';
import createToolbarPlugin from '@draft-js-plugins/static-toolbar';
import '@draft-js-plugins/static-toolbar/lib/plugin.css';
import styled from '@emotion/styled';
import { useTheme } from '@mui/material';
import React, { useCallback, useRef, useState } from 'react';
import { useSearchUsersLazyQuery } from '../../../generated/graphql';

const inlineToolbarPlugin = createInlineToolbarPlugin();
// const { InlineToolbar } = inlineToolbarPlugin;

const Wrapper = styled.div<any>`
  .editor {
    box-sizing: border-box;
    border: ${({ readOnly }) => (readOnly ? 'none' : '1px solid #ccc')};
    cursor: text;
    border-radius: 2px;
    /* margin-bottom: 2em; */

    background: transparent;
    min-height: ${({ readOnly }) => (readOnly ? 'auto' : '160px')};
  }

  .DraftEditor-root {
    padding: ${({ readOnly }) => (readOnly ? '0' : '15px')};
  }

  .editor :global(.public-DraftEditor-content) {
    min-height: 140px;
  }

  /* Mention popup */
  .mnw6qvm {
    background: ${({ theme }) => theme?.palette?.background?.paper || 'white'};
  }

  /* Mention hover item */
  .myz2dw1 {
    opacity: 0.7;
    background: ${({ theme }) => (theme?.palette?.mode === 'dark' ? '#1e1e1e' : 'white')};
  }

  /* Mention Color */
  .m6zwb4v:hover,
  .m6zwb4v:focus {
    cursor: default;
  }
`;

interface DraftRichTextProps {
  readOnly?: boolean;
  draftState: any;
  setDraftState?: (draftState: any) => void;
  ariaLabel?: string;
}

export const DraftRichText: React.FC<DraftRichTextProps> = ({
  readOnly,
  draftState,
  ariaLabel,
  setDraftState,
}) => {
  const theme = useTheme();

  const [suggestions, setSuggestions] = useState<any>([]);
  const [query] = useSearchUsersLazyQuery({
    onCompleted: (data) =>
      setSuggestions(
        data?.searchUsers?.map((u) => {
          return { name: u.name, id: u.id };
        }),
      ),
  });

  const [{ plugins, Toolbar, MentionSuggestions, linkPlugin }] = useState(() => {
    const staticToolbarPlugin = createToolbarPlugin();
    const linkPlugin = createLinkPlugin();
    const linkifyPlugin = createLinkifyPlugin({ target: '_blank' });
    const { Toolbar } = staticToolbarPlugin;

    const mentionPlugin = createMentionPlugin({
      supportWhitespace: true,
      entityMutability: 'IMMUTABLE',
    });
    // eslint-disable-next-line no-shadow
    const { MentionSuggestions } = mentionPlugin;
    // eslint-disable-next-line no-shadow
    const plugins = [
      mentionPlugin,
      inlineToolbarPlugin,
      linkPlugin,
      linkifyPlugin,
      staticToolbarPlugin,
    ];
    return {
      plugins,
      MentionSuggestions,
      Toolbar,
      linkPlugin,
    };
  });

  const ref = useRef<Editor>(null);
  const [open, setOpen] = useState(false);

  const onOpenChange = useCallback((_open: boolean) => {
    setOpen(_open);
  }, []);
  const onSearchChange = useCallback(async ({ value }: { value: string }) => {
    await query({
      variables: {
        search: value,
        limit: 4,
      },
    });
  }, []);

  if (!draftState?.getCurrentContent) {
    return <div>Error</div>;
  }

  return (
    <Wrapper theme={theme} readOnly={readOnly} className="wrapper">
      {/* <button onClick={() => onExtractMentions()}>Extract mentions</button> */}
      <div className="editor" onClick={() => ref.current && ref.current.focus()}>
        {readOnly ? (
          ''
        ) : (
          <Toolbar>
            {
              // may be use React.Fragment instead of div to improve perfomance after React 16
              (externalProps: any) => (
                <div>
                  <BoldButton {...externalProps} />
                  <ItalicButton {...externalProps} />
                  <UnderlineButton {...externalProps} />
                  <linkPlugin.LinkButton {...externalProps} />
                </div>
              )
            }
          </Toolbar>
        )}

        <Editor
          role="text-box"
          editorState={draftState}
          readOnly={readOnly}
          ariaLabel={ariaLabel}
          onChange={(state) => {
            if (typeof setDraftState === 'function') {
              setDraftState(state);
            }
          }}
          plugins={plugins}
          ref={ref}
        />
        <MentionSuggestions
          open={open}
          renderEmptyPopup
          onOpenChange={onOpenChange}
          suggestions={suggestions}
          onSearchChange={onSearchChange}
          onAddMention={() => {
            // get the mention object selected
          }}
        />

        {/* <InlineToolbar>
          {
            // may be use React.Fragment instead of div to improve perfomance after React 16
            (externalProps: any) => (
              <div>
                <BoldButton {...externalProps} />
                <ItalicButton {...externalProps} />
                <UnderlineButton {...externalProps} />
                <linkPlugin.LinkButton {...externalProps} />
              </div>
            )
          }
        </InlineToolbar> */}
      </div>
    </Wrapper>
  );
};
