import { StoreSlice } from './store';
import { TaskTypes, threeDVendors } from '../utils/constants';
import { PayloadFilterStatuses, TaskStatuses, User } from '../generated/graphql';

interface Filters {
  search: string;
  taskTypeId?: TaskTypes;
  status?: TaskStatuses;
  owner?: User | null;
  similarPropUrl: PayloadFilterStatuses | '';
  vendorId?: threeDVendors;
}

export interface FiltersSlice {
  filters: Filters,
  setFilters: (filter: Partial<Filters>) => void
};

export const filters: StoreSlice<FiltersSlice> = (set) => ({
  filters: {
    search: '',
    similarPropUrl: ''
  },
  setFilters: (filter) => set(({ filters }) => ({
      filters: { ...filters, ...filter }
    })
  )
});
