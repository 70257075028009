// TODO: get this list from the backend. Create an resolver that returns the list of tenants
export enum Tenants {
  Havenly = '1',
  SonnyAndAsh = '2',
  eSoft = '3',
  HavenlyInHouse = '4',
}

export enum PrivilegeLevels {
  User = 'User',
  Admin = 'Admin',
}
