import { Alert, Snackbar } from "@mui/material";
import { FC, createContext, useState } from "react";

export enum SnackbarTypes {
  SUCCESS = 'success',
  ERROR = 'error'
}

interface SnackState {
  open: boolean,
  message: string,
  type: SnackbarTypes
}


const defaultState: SnackState = {
  open: false,
  message: '',
  type: SnackbarTypes.SUCCESS
}

interface ISnackbarContext {
  snackThis: (message: string) => void
  errorThis: (message: string) => void
}

export const SnackbarContext = createContext<ISnackbarContext>({
  snackThis: () => null,
  errorThis: () => null
});

export const SnackbarProvider: FC = ({ children }) => {
  const [{ open, message, type}, setSnackState] = useState<SnackState>(defaultState);
  
  const onClose = () => setSnackState(defaultState);
  
  const snackThis = (message: string) => {
    setSnackState({
      open: true, message, type: SnackbarTypes.SUCCESS
    })
  }
  
  const errorThis = (message: string) => {
    setSnackState({
      open: true, message, type: SnackbarTypes.ERROR
    })
  }
  
  return (
  <SnackbarContext.Provider
    value={{ snackThis, errorThis }}
  >
    <>
      { children }
      <Snackbar
        open={open}
        message={message}
        autoHideDuration={6000}
        onClose={onClose}
      >
         <Alert severity={type}>
          { message }
        </Alert> 
      </Snackbar>

    </>
  </SnackbarContext.Provider>
  )
}