import React, { useEffect } from 'react';
import { Navigate, Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import { QueryParamProvider } from 'use-query-params';
import { SignIn } from '../../pages/Auth/SignIn';
import { SignOut } from '../../pages/Auth/SignOut';
import { TaskPage } from '../../pages/Task/TaskPage';
import { Dashboard } from '../../pages/Dashboard/Dashboard';
import { Home } from '../../pages/Home/Home';
import { ManageUsers } from '../../pages/ManageUsers/ManageUsers';
import { useStore } from '../../store/store';
import { LoadingScreen } from '../common/LoadingScreen/LoadingScreen';
import { ProtectedRoute } from './ProtectedRoute';
import { useMeQuery } from '../../generated/graphql';
import { isHavenlyAdmin, isUserInAnyGroup } from '../../utils/groups';

export const Router: React.FC = () => {
  const { accessToken } = useStore();
  const { loading, data, refetch } = useMeQuery({
    skip: accessToken === null,
  });

  const inAnyGroup = isUserInAnyGroup(data);

  useEffect(() => {
    if (accessToken) {
      refetch();
    }
  }, [accessToken, refetch]);

  // this is causing problems with the SignIn route and i haven't been able to figure it out yet.
  // the SignIn route is mounted, accessToken changes causing refetch, which causes SignIn to be unmounted
  // and LoadingScreen to be mounted. When loading finishes LoadingScreen is unmounted and SignIn is re-mounted,
  // causing us to fetch the token a second time.
  // We want to show a loading screen if loading because a fresh page load we need to fetch the user
  // before we know what routes they should have access to.
  if (loading) {
    return <LoadingScreen fullScreen />;
  }

  return (
    <QueryParamProvider ReactRouterRoute={RouteAdapter}>
      <Routes>
        <Route path="/signin" element={<SignIn />} />
        <Route path="/signout" element={<SignOut />} />

        <Route element={<ProtectedRoute isAllowed={!inAnyGroup} redirectPath="/dashboard" />}>
          <Route path="/" element={<Home />} />
        </Route>

        <Route element={<ProtectedRoute isAllowed={inAnyGroup} />}>
          <Route path="/dashboard" element={<Dashboard />} />
        </Route>

        <Route
          path="/manage-users"
          element={
            <ProtectedRoute isAllowed={isHavenlyAdmin(data)} redirectPath="/dashboard">
              <ManageUsers />
            </ProtectedRoute>
          }
        />

        <Route path="/tasks" element={<Navigate to="/dashboard" replace />} />

        <Route path="/tasks/:taskId" element={<TaskPage />} />
      </Routes>
    </QueryParamProvider>
  );
};

/**
 * This is the main thing you need to use to adapt the react-router v6
 * API to what use-query-params expects.
 *
 * Pass this as the `ReactRouterRoute` prop to QueryParamProvider.
 */
export const RouteAdapter = ({ children }: any) => {
  const navigate = useNavigate();
  const location = useLocation();

  const adaptedHistory = React.useMemo(
    () => ({
      replace(location: any) {
        navigate(location, { replace: true, state: location.state });
      },
      push(location: any) {
        navigate(location, { replace: false, state: location.state });
      },
    }),
    [navigate],
  );
  return children({ history: adaptedHistory, location });
};
