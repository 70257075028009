import { Container } from '@mui/material';
import { NumberParam, useQueryParam } from 'use-query-params';
import { TaskTable } from '../../components/TaskTable/TaskTable';
import { useEffect } from 'react';
import { useNavigateToTask } from '../../hooks/useNavigateToTask';

export const Dashboard: React.FC = () => {
  const [taskId] = useQueryParam('task-id', NumberParam);
  const navigate = useNavigateToTask();

  useEffect(() => {
    if (taskId && !isNaN(taskId)) {
      navigate(taskId, { replace: true });
    }
  }, [taskId, navigate])
  
  return (
    <Container maxWidth="xl">
      <main>
        <TaskTable />
      </main>
    </Container>
  );
};
