import React from 'react';
import { Block } from '@mui/icons-material';
import { ListItemIcon, ListItemText, MenuItem } from '@mui/material';
import { TaskStatuses } from '../../../../../generated/graphql';
import { ActionMenuItemProps } from './MenuInterface';

export const BlockMenuItem: React.FC<ActionMenuItemProps> = ({
  handleMenuClick, task
}) => {
  
  return (
    <>
      <MenuItem onClick={handleMenuClick}>
        <ListItemIcon>
          <Block fontSize="small" />
        </ListItemIcon>

        <ListItemText>
          {
            task.status === TaskStatuses.Blocked
            ? 'Unblock'
            : 'Block'
          }
        </ListItemText>
      </MenuItem>
    </>
  );
}