import AttachmentIcon from '@mui/icons-material/Attachment';
import { Box, Link, SxProps, Typography } from '@mui/material';
import React from 'react';

type Attachment = { __typename?: 'Attachment'; id: number; url: string };

interface AttachmentsProps {
  attachments: any;
  sx: SxProps;
}

export const Attachments: React.FC<AttachmentsProps> = ({ attachments, sx }) => {
  return (
    <Box sx={sx}>
      <Typography variant="subtitle2">Attachments</Typography>
      {attachments.map((attachment: Attachment) => {
        return (
          <Box key={attachment.id} sx={{ mt: 1 }}>
            <Link
              href={attachment.url || '#'}
              target="_blank"
              sx={{ display: 'flex', alignItems: 'center' }}
            >
              <AttachmentIcon sx={{ mr: 1 }} /> {attachment.url}
            </Link>
          </Box>
        );
      })}
    </Box>
  );
};
