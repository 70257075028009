import { Box } from '@mui/material';
import React from 'react';
import { ControlledTextField } from '../../ControlledTextField/ControlledTextField';

interface PropUpTaskFormProps {
  control: any;
  watch: any;
}

export const PropUpTaskForm: React.FC<PropUpTaskFormProps> = ({ control }) => {
  return (
    <Box
      sx={{
        marginTop: '15px',
        display: 'grid',
        gridTemplateColumns: { xs: '1fr', md: '1fr 1fr' },
        gridGap: '15px',
      }}
    >
      <ControlledTextField
        label="Product Taxonomy"
        name="productTaxonomy"
        control={control}
        required
      />
      <ControlledTextField label="Kustomer ID" name="kustomerId" control={control} />
      <ControlledTextField label="Havenly Asset ID" name="havenlyAssetId" control={control} />
      <ControlledTextField label="Source URL" name="sourceUrl" control={control} required />
      <ControlledTextField label="Similar Prop URL" name="similarPropUrl" control={control} />
      <ControlledTextField label="Requester ID" name="requesterId" control={control} required />

      <ControlledTextField
        label="Vendor Variant Image URL"
        name="vendorVariantImageUrl"
        control={control}
        required
      />
      <ControlledTextField
        label="Vendor Variant Title"
        name="vendorVariantTitle"
        control={control}
        required
      />
      <ControlledTextField label="Base Asset Hash" name="baseAssetHash" control={control} />
      <ControlledTextField label="Asset URL" name="assetUrl" control={control} />
      <ControlledTextField label="Asset Metadata" name="assetMetadata" control={control} />
    </Box>
  );
};
