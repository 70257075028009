import React from 'react';
import { Cancel } from '@mui/icons-material';
import { ActionMenuItemProps } from './MenuInterface';
import { useCancelTaskMutation } from '../../../../../generated/graphql';
import { BaseMenuItem, refetchQueries, useBaseMenuAction } from '../BaseMenuItem';


export const CancelMenuItem: React.FC<ActionMenuItemProps> = ({ handleMenuClick, task }) => {
  const [cancelTask, { loading }] = useCancelTaskMutation();
  const { action } = useBaseMenuAction(
    () => cancelTask({
      refetchQueries,
      variables: {
        taskId: task.id.toString()
      }
    }),
    handleMenuClick,
    'Task cancelled successfully',
    'Error cancelling task'
  )
  
  return (
    <BaseMenuItem
      menuClick={action}
      loading={loading}
      icon={<Cancel fontSize="small" />}
      text="Cancel"
    />
  );
}