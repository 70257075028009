import { CognitoGroups, MeQuery, useCreateUserMutation } from '../generated/graphql';
import { FormValues } from './CreateUserModal';
import { PrivilegeLevels, Tenants } from '../constants/tenants';
import { UnknownUserError } from '../errors/UnknowUserError';

type Me = MeQuery['me'] | undefined;

export default function useCreateUser() {
  const [createUser, { loading }] = useCreateUserMutation();

  const createUserMutation = async (formData: FormValues, me: Me) => {
    const { tenant, privilegeLevel, name, email } = formData;
    const group: CognitoGroups = _figureOutCognitoGroup(tenant, privilegeLevel, me);

    return await createUser({
      variables: {
        name,
        email,
        group,
        tenantId: tenant,
      },
    });
  };

  return { createUserMutation, loading };
}
export function _figureOutCognitoGroup(
  tenant: Tenants | undefined,
  privilegeLevel: PrivilegeLevels | undefined,
  me: Me,
): CognitoGroups {
  if (!me?.groups?.length) {
    throw new UnknownUserError();
  }

  if (!me.groups.includes(CognitoGroups.HavenlyAdmin)) {
    return me.groups[0];
  }

  if (tenant === Tenants.Havenly) {
    if (privilegeLevel === PrivilegeLevels.Admin) {
      return CognitoGroups.HavenlyAdmin;
    }

    if (privilegeLevel === PrivilegeLevels.User) {
      return CognitoGroups.HavenlyUser;
    }
  }

  if (privilegeLevel === PrivilegeLevels.Admin) {
    return CognitoGroups.TenantAdmin;
  }

  if (privilegeLevel === PrivilegeLevels.User) {
    return CognitoGroups.TenantUser;
  }

  throw new Error('Failed to determine Cognito Group');
}
