import { DatePicker } from "@mui/lab";
import { TextField, TextFieldProps } from "@mui/material";
import React from "react";
import { Controller } from "react-hook-form";

interface ControlledDatePickerProps {
  control: any;
  name: string;
  value: any;
  rules?: any;
  classes?: string[];
  label: string;
  required?: boolean;
  onChange: (date: any) => void;
}

export const ControlledDatePicker: React.FC<ControlledDatePickerProps> = (
  props
) => {
  const { control, name, rules, label, onChange, value, required } = props;

  const handleChange = (date: any, field: any) => {
    field.onChange(date);
    onChange(date);
  };

  return (
    <Controller
      control={control}
      name={name}
      rules={rules}
      defaultValue={value}
      render={({ field, fieldState: { error } }) => {
        return (
          <DatePicker
            minDate={new Date()}
            label={required ? `${label} *` : label}
            value={value}
            onChange={(date) => handleChange(date, field)}
            renderInput={(params: TextFieldProps) => (
              <TextField
                {...params}
                error={!!error?.message}
                helperText={error?.message}
                sx={{ mt: 2 }}
              />
            )}
          />
        );
      }}
    />
  );
};
