import React, { FC } from 'react';
import Modal from '@mui/material/Modal';
import { Box } from '@mui/material';

const style = {
  bgcolor: 'background.paper',
  boxShadow: 24,
  left: '50%',
  maxWidth: '100%',
  overflow: 'auto',
  px: 3,
  py: 2,
  position: 'absolute' as 'absolute',
  top: '50%',
  transform: 'translate(-50%, -50%)',
  width: 540,
};

export interface BaseModalProps {
  isModalOpen: boolean;
  onClose: () => void;
  children?: React.ReactNode;
}

const BaseModal: FC<BaseModalProps> = ({ isModalOpen, onClose, children }) => {
  return (
    <Modal open={isModalOpen} onClose={onClose}>
      <Box sx={style}>{children}</Box>
    </Modal>
  );
};

export default BaseModal;
