import { Breadcrumbs, Typography } from "@mui/material";
import Link from "@mui/material/Link";
import React from "react";
import { useNavigateToTask } from "../../../hooks/useNavigateToTask";

interface BreadcrumbProps {
  parentTaskId: number;
  id: number;
}

export const Breadcrumb: React.FC<BreadcrumbProps> = ({ parentTaskId, id }) => {
  const navigate = useNavigateToTask()

  return (
    <Breadcrumbs sx={{ mb: 2 }} aria-label='breadcrumb'>
      <Link
        component='button'
        onClick={() => navigate(parentTaskId)}
        underline='hover'
        color='inherit'
      >
        Parent Task
      </Link>
      <Typography color='text.primary'>Subtask #{id}</Typography>
    </Breadcrumbs>
  );
};
