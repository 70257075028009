import React, { useState } from 'react';
import { Alert, Autocomplete, CircularProgress, TextField } from '@mui/material';
import { User, useSearchUsersQuery } from '../../../../generated/graphql';
import { useDebounce } from '../../../../hooks/useDebounce';
import { useEZStore } from '../../../../store/store';

const notAssigned: User = {
  id: -1,
  name: 'Not assigned',
  cognitoUsername: 'N/A',
  email: 'N/A'
};

export const FilterOwnerAutocomplete: React.FC = () => {
  const [inputValue, setInputValue] = useState<string | null>(null);
  const [open, setOpen] = useState(false);
  const { filters: { owner }, setFilters } = useEZStore();

  const { loading, error, autocompleteOptions }
    = useGetAutoCompleteOptions(inputValue);

  if (error) {
    return (<Alert severity="error"> Error filtering users: {`${error}`}</Alert>);
  }

  return (
    <Autocomplete
      onFocus={() => { setInputValue(''); }}
      value={owner}
      sx={{ width: 200, size: 1 }}
      open={open}
      onOpen={() => { setOpen(true); }}
      onClose={() => { setOpen(false); }}
      isOptionEqualToValue={(option, value) => option.name === value.name}
      getOptionLabel={(option) => option.name}
      options={[ notAssigned, ...autocompleteOptions]}
      loading={loading}
      onChange={(_, owner) => {
        setFilters({ owner });
      }}
      onInputChange={(_, newInputValue) => {
        setInputValue(newInputValue);
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          label="Search by owner"
          type="text"
          variant="standard"
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <React.Fragment>
                {loading ? <CircularProgress color="inherit" size={20} /> : null}
                {params.InputProps.endAdornment}
              </React.Fragment>
            ),
          }}
        />
      )}
    />
  );
};

function useGetAutoCompleteOptions(inputValue: string | null) {
  const debouncedValue = useDebounce<string | null>(inputValue, 300);

  let autocompleteOptions: User[] = [];
  const { loading, error, data } = useSearchUsersQuery({
    skip: inputValue === null,
    variables: {
      search: debouncedValue || '',
    },
  });

  if (data) {
    autocompleteOptions = data.searchUsers;
  }

  return {
    loading, error, autocompleteOptions
  }
}
