import { Box, Button, Container, Typography } from '@mui/material';
import { useMeQuery } from '../../generated/graphql';
import { useSignOut } from '../../hooks/useSignOut';
import { useStore } from '../../store/store';

export const Home: React.FC = () => {
  const signInUrl = `${process.env.REACT_APP_AWS_COGNITO_AUTH}/login?client_id=${process.env.REACT_APP_AWS_COGNITO_CLIENT_ID}&response_type=code&scope=email+openid+phone+profile&redirect_uri=${process.env.REACT_APP_AWS_COGNITO_CALLBACK_SIGN_IN}`;
  const signOut = useSignOut();

  const { accessToken } = useStore();
  const { data } = useMeQuery({
    skip: accessToken === null,
  });

  return (
    <Container maxWidth="xl">
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          mt: 36,
          flexDirection: 'column',
        }}
      >
        {data?.me && (!data.me.groups || data.me.groups.length === 0) && (
          <Box>
            <Typography
              variant="h5"
              component="div"
              sx={{ textAlign: 'center', maxWidth: 600, mb: 4 }}
            >
              You've successfully created your Lunes account. In order to access its features,
              please contact a Havenly Admin providing your user email and your account will be
              activated
            </Typography>
          </Box>
        )}
        <Button
          size="large"
          variant="contained"
          onClick={async () => {
            await signOut();
            window.location.href = signInUrl;
          }}
          sx={{ display: 'flex', justifyContent: 'center' }}
        >
          Sign In
        </Button>
      </Box>
    </Container>
  );
};
