/** @jsxRuntime classic */
/** @jsx jsx */
import { css, jsx } from '@emotion/react';
import { useTheme } from '@mui/material';
import React from 'react';

interface LoadingScreenProps {
  fullScreen?: boolean;
}

export const LoadingScreen: React.FC<LoadingScreenProps> = ({ fullScreen }) => {
  const theme = useTheme();
  return (
    <div
      css={css`
        background: ${theme?.palette?.background?.default || 'white'};
        height: 100%;
        min-height: 0;
        position: ${fullScreen ? 'absolute' : 'initial'};
        top: 0;
        width: 100%;

        svg {
          -webkit-animation: pulse 2s infinite;
          animation: pulse 2s infinite;
          height: 100px;
          left: calc(50% - 50px);
          position: absolute;
          top: calc(50% - 50px);
          width: 100px;
        }

        @keyframes pulse {
          0% {
            opacity: 0;
            -webkit-transform: scale(0.666);
            transform: scale(0.666);
          }
          50% {
            opacity: 0.5;
          }
          100% {
            opacity: 0;
            -webkit-transform: scale(1);
            transform: scale(1);
          }
        }
      `}
      className="client-side-loading-screen"
    >
      <svg viewBox="0 0 1200 1200">
        <path
          fill="#404042"
          d="M925.46 454a353.4 353.4 0 1 0 27.69 137.12A351.08 351.08 0 0 0 925.46 454zm-246.38 44.34a10.31 10.31 0 0 0-3 7.29v75.17H525.7v-75.17a10.31 10.31 0 0 0-3-7.29l-67.9-67.88H747zm-174 11.57v162.43l-64.87 64.86V445zm17.61 174a10.31 10.31 0 0 0 3-7.29v-75.18h150.36v75.17a10.31 10.31 0 0 0 3 7.29L747 751.79H454.8zm174-11.57V509.9l64.85-64.9v292.2zM454.8 409.83l146.08-146.08L747 409.83H454.8zM419.59 737.2L273.51 591.12 419.59 445v292.2zM747 772.41L600.88 918.49 454.8 772.41H747zM782.17 445l146.08 146.12L782.17 737.2V445zm53.21-88.43a329.16 329.16 0 0 1 96.13 208.6L779.16 412.86 626.78 260.48a329.16 329.16 0 0 1 208.61 96.13zm-469 0A329.16 329.16 0 0 1 575 260.48L270.24 565.22a329.16 329.16 0 0 1 96.13-208.61zm0 469A329.16 329.16 0 0 1 270.24 617L575 921.76a329.16 329.16 0 0 1-208.63-96.13zm469 0a329.16 329.16 0 0 1-208.6 96.13l152.36-152.3L931.52 617a329.16 329.16 0 0 1-96.13 208.63z"
        />
      </svg>
    </div>
  );
};
