// Keeping this instead of using TaskStatuses
// directly because "Blocked"
// should be a button
export const statuses = [
  {
    value: 'Pending',
    label: 'Pending',
  },
  {
    value: 'InProgress',
    label: 'InProgress',
  },
  {
    value: 'OnHold',
    label: 'OnHold',
  },
  {
    value: 'Complete',
    label: 'Complete',
  },
  {
    value: 'Cancelled',
    label: 'Cancelled',
  },
];

export enum TaskTypes {
  "Prop" = 1,
  "Touch Up" = 3,
  "Extra" = 4,
  "Material" = 5,
  "Room Shell" = 6,
  "Generic Prop" = 7
};

export enum threeDVendors {
  'Unknown' = -1,
  'S+A' = 2,
  "eSoft" = 3,
  "Havenly" = 4
};
