import Container from '@mui/material/Container';
import React from 'react';
import { UserTable } from '../../components/UserTable/UserTable';

interface ManageUsersProps {}

export const ManageUsers: React.FC<ManageUsersProps> = () => {
  return (
    <Container maxWidth="xl">
      <UserTable />
    </Container>
  );
};
