import { Autocomplete, CircularProgress, TextField } from '@mui/material';
import React, { useState } from 'react';
import { useSearchUsersQuery } from '../../../../../generated/graphql';
import { useDebounce } from '../../../../../hooks/useDebounce';

interface SearchUserInputProps {
  onSelectOwner: (owner: any) => void;
  taskOwnerIds: number[] | null;
}

export const SearchUserInput: React.FC<SearchUserInputProps> = ({
  onSelectOwner,
  taskOwnerIds,
}) => {
  const [inputValue, setInputValue] = useState('');
  const [value] = useState<any>(null);
  const [open, setOpen] = useState(false);

  const debouncedValue = useDebounce<string>(inputValue, 300);

  const { loading, error, data } = useSearchUsersQuery({
    variables: {
      search: debouncedValue,
      excludeUserIds: taskOwnerIds,
    },
  });

  if (error) {
    return <div>Sorry there was an error: {`${error}`}</div>;
  }

  return (
    <Autocomplete
      value={value}
      sx={{ width: 200, size: 1 }}
      open={open}
      onOpen={() => {
        setOpen(true);
      }}
      onClose={() => {
        setOpen(false);
      }}
      isOptionEqualToValue={(option, value) => option.name === value.name}
      getOptionLabel={(option) => option.name}
      options={data?.searchUsers ? data?.searchUsers : []}
      loading={loading}
      onChange={(event, value) => {
        if (value) {
          onSelectOwner(value);
        }
      }}
      onInputChange={(event, newInputValue) => {
        setInputValue(newInputValue);
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          value={inputValue}
          label="Assign an owner"
          type="text"
          variant="standard"
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <React.Fragment>
                {loading ? <CircularProgress color="inherit" size={20} /> : null}
                {/* {params.InputProps.endAdornment} */}
              </React.Fragment>
            ),
          }}
        />
      )}
    />
  );
};
