import { FormControl, InputLabel, MenuItem, Select, SelectChangeEvent } from '@mui/material';
import React from 'react';
import { useTaskTypesQuery } from '../../../../generated/graphql';
import { useEZStore } from '../../../../store/store';

export const FilterTypeSelect: React.FC = () => {
  const { data, loading } = useTaskTypesQuery();
  const { filters: { taskTypeId }, setFilters } = useEZStore();

  const handleChangeType = (event: SelectChangeEvent) => {
    let taskTypeId = parseInt(event.target.value);
    setFilters({ taskTypeId });
  };

  return (
    <FormControl variant="standard" sx={{ m: 0, minWidth: 120 }}>
      <InputLabel id="type-label">Type</InputLabel>
      <Select
        labelId="type-label"
        id="taskTypeId"
        label="Type"
        value={taskTypeId ? taskTypeId.toString() : ''}
        disabled={loading}
        onChange={handleChangeType}
      >
        <MenuItem value="">
          <em>View All</em>
        </MenuItem>
        {(data?.taskTypes || []).map((taskType) => (
          <MenuItem key={taskType.name} value={taskType.id}>
            {`${taskType.name}`}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};
