import React from 'react';
import { Box, BoxProps } from '@mui/system';
import { Typography } from '@mui/material';

interface FieldProps extends BoxProps {
  title: string;
  text: React.ReactNode | string | undefined | null;
}

export default function Field({ title, text, ...props }: FieldProps) {
  return (
    <Box key={title} {...props}>
      <Typography variant='subtitle2'>{title}</Typography>
      <Typography variant='body2'>{text || 'N/A'}</Typography>
    </Box>
  );
}
