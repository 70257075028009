import { StoreSlice } from './store';
type themeType = 'light' | 'dark';

export type ThemeSlice = {
  theme: themeType;
  toggleTheme: () => void;
};

export const themeSlice: StoreSlice<ThemeSlice> = (set) => ({
  theme: 'light',
  toggleTheme: () => set((state) => ({ theme: state.theme === 'light' ? 'dark' : 'light' })),
});
