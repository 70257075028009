import { TextField, TextFieldProps } from '@mui/material';
import React from 'react';
import { useController } from 'react-hook-form';

interface ControlledTextFieldProps {
  control: any;
  isArray?: boolean;
}

export const ControlledTextField: React.FC<TextFieldProps & ControlledTextFieldProps> = ({
  name,
  control,
  required,
  label,
  ...props
}) => {
  const {
    fieldState: { error },
    field: { onChange, onBlur, value, ref },
  } = useController({
    name: typeof name === 'string' ? name : '',
    control,
    rules: { required: required },
    defaultValue: '',
  });

  return (
    <TextField
      fullWidth
      helperText={error?.message}
      error={!!error?.message}
      onChange={onChange} // send value to hook form
      onBlur={onBlur} // notify when input is touched/blur
      value={value} // input value
      inputRef={ref} // send input ref, so we can focus on input when error appear
      label={required ? `${label} *` : label}
      {...props}
    />
  );
};
