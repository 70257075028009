import { FormControl, InputLabel, MenuItem, Select, SelectChangeEvent } from '@mui/material';
import React from 'react';
import { TaskStatuses } from '../../../../generated/graphql';
import { useEZStore } from '../../../../store/store';

export const FilterStatusSelect: React.FC = () => {
  const statuses = getOrderedAndLabeledStatuses();
  const { filters: { status }, setFilters } = useEZStore();

  const handleChangeStatus = (event: SelectChangeEvent) => {
    setFilters({ status: TaskStatuses[event.target.value as TaskStatuses] });
  };

  return (
    <FormControl variant="standard" sx={{ m: 0, minWidth: 120 }}>
      <InputLabel id="status-label">Status</InputLabel>
      <Select
        labelId="status-label"
        id="status"
        label="Status"
        value={status}
        onChange={handleChangeStatus}
      >
        <MenuItem value="">
          <em>View All</em>
        </MenuItem>
        {statuses.map((option) => (
          <MenuItem key={option.value} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

const statusesOrder: {[key: string]: number} = {
  HavenlyReview: 1,
  Pending: 2,
  InProgress: 3,
  Complete: 4,
  Blocked: 5,
  Cancelled: 6,
  OnHold: 7,
  Error: 8
};

function getOrderedAndLabeledStatuses() {
  return Object.values(TaskStatuses)
    .sort((a, b) => statusesOrder[a] > statusesOrder[b] ? 1 : -1)
    .map((taskStatus) => ({
      value: taskStatus,
      label: taskStatus
    }));
}
