import React, { useEffect, useState } from 'react';

import { alpha, Box, Button, LinearProgress, Typography } from '@mui/material';
import TextField from '@mui/material/TextField';
import Toolbar from '@mui/material/Toolbar';

import { FilterPayloadFieldInput } from '../../../generated/graphql';
import { AssignDueDateToTasks } from './AssignDueDateToTasks/AssignDueDateToTasks';
import { AssignOwnersToTasks } from './AssignOwnersToTasks/AssignOwnersToTasks';
import { AssignStatusToTasks } from './AssignStatusToTasks/AssignStatusToTasks';
import { FilterOwnerAutocomplete } from './FilterOwnerAutocomplete/FilterOwnerAutocomplete';
import { FilterSimilarPropUrl } from './FilterSimilarPropUrl/FilterSimilarPropUrl';
import { FilterStatusSelect } from './FilterStatusSelect/FilterStatusSelect';
import { FilterTypeSelect } from './FilterTypeSelect/FilterTypeSelect';
import { useEZStore } from '../../../store/store';
import { FilterVendorSelect } from './FilterVendorSelect/FilterVendorSelect';
import { isHavenlyAdminOrUser } from '../../../utils/groups';

interface TableToolbarProps {
  loading: boolean;
  numSelected: number;
  selectedTaskIds: number[];
  onResetSelectedTaskIds: () => void;
  handleFilterPayloadFields: (newFilterPayloadField: FilterPayloadFieldInput) => void;
  clearFilterPayloadField: (payloadField: string) => void;
}

export const TableToolbar: React.FC<TableToolbarProps> = ({
  loading,
  numSelected,
  selectedTaskIds,
  onResetSelectedTaskIds,
  handleFilterPayloadFields,
  clearFilterPayloadField,
}) => {
  const { filters: { search }, setFilters } = useEZStore();
  const [searchInput, setSearchInput] = useState<string>(search || '');
  const { user } = useEZStore();
  const canFilterVendors = isHavenlyAdminOrUser(user);

  const handleClearSearch = () => {
    setSearchInput('');
    setFilters({ search: '' });
  }

  useEffect(() => {
    if (searchInput === '') {
      handleClearSearch()
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchInput])

  return (
    <Toolbar
      sx={{
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 },
        ...(numSelected > 0 && {
          bgcolor: (theme) =>
            alpha(theme.palette.primary.main, theme.palette.action.activatedOpacity),
        }),
      }}
    >
      <Box
        sx={{
          mt: 1,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'baseline',
          width: numSelected > 0 ? '100%' : 'fit-content',
        }}
      >
        <Box sx={{ display: 'flex', width: '100%' }}>
          {numSelected > 0 ? (
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                width: '100%',
                gap: '12px',
              }}
            >
              <Typography>{numSelected} selected</Typography>
              <Box sx={{ display: 'flex', gap: 2 }}>
                <AssignDueDateToTasks
                  selectedTaskIds={selectedTaskIds}
                  onResetSelectedTaskIds={onResetSelectedTaskIds}
                />

                <AssignOwnersToTasks
                  selectedTaskIds={selectedTaskIds}
                  onResetSelectedTaskIds={onResetSelectedTaskIds}
                />

                <AssignStatusToTasks
                  selectedTaskIds={selectedTaskIds}
                  onResetSelectedTaskIds={onResetSelectedTaskIds}
                />
              </Box>
            </Box>
          ) : (
            <>
              <form
                onSubmit={(event) => {
                  event.preventDefault();
                  setFilters({ search: searchInput })
                }}
              >
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'flex-end',
                    mr: 3,
                  }}
                >
                  <TextField
                    sx={{ width: '200px', maxWidth: '100%' }}
                    id="search"
                    label="Search"
                    variant="standard"
                    value={searchInput}
                    onChange={(event) => setSearchInput(event.target.value)}
                  />
                  <Button sx={{ ml: 2 }} type="submit" variant="outlined">
                    Search
                  </Button>
                  <Button
                    sx={{ ml: 2 }}
                    type="button"
                    disabled={!search}
                    onClick={handleClearSearch}
                  >
                    Clear
                  </Button>
                </Box>
              </form>

              <Box
                sx={{
                  display: 'flex',
                  flexWrap: 'wrap',
                }}
              >
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'flex-end',
                    mr: 3,
                  }}
                >
                  <FilterTypeSelect />
                </Box>
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'flex-end',
                    mr: 3,
                  }}
                >
                  <FilterStatusSelect />
                </Box>
                {canFilterVendors && <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'flex-end',
                    mr: 3,
                  }}
                >
                  <FilterVendorSelect />
                </Box>}
                <Box
                  sx={{
                    mr: 3,
                  }}
                >
                  <FilterOwnerAutocomplete />
                </Box>

                <Box>
                  <FilterSimilarPropUrl
                    clearFilterPayloadField={clearFilterPayloadField}
                    handleFilterPayloadFields={handleFilterPayloadFields}
                  />
                </Box>
              </Box>
            </>
          )}
        </Box>

        <Box sx={{ width: '100%', mt: 2 }}>{loading && <LinearProgress />}</Box>
      </Box>
    </Toolbar>
  );
};
