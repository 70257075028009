import { Box, TableCell, TableSortLabel } from '@mui/material';
import { visuallyHidden } from '@mui/utils';
import React from 'react';
import { FieldsToOrder as FieldsToOrderTask } from '../../TaskTable/TaskTable';
import { FieldsToOrder as FieldsToOrderUser } from '../../UserTable/UserTable';

type FieldsToOrder = FieldsToOrderTask & FieldsToOrderUser;

export type IOrderDirection = 'asc' | 'desc';

export interface IHeadCell {
  id: keyof FieldsToOrder;
  label: string;
  allowsOrder: boolean;
}

interface TableHeadCellProps {
  orderField?: any;
  headCell: { id: keyof FieldsToOrder; label: string; allowsOrder: boolean };
  orderDirection?: any;
  setOrderField?: (field: any) => void;
  setOrderDirection?: (direction: IOrderDirection) => void;
}

export const TableHeadCell: React.FC<TableHeadCellProps> = ({
  headCell,
  orderField,
  orderDirection,
  setOrderDirection,
  setOrderField,
}) => {
  return (
    <TableCell
      key={headCell.id}
      align="center"
      sortDirection={orderField === headCell.id ? orderDirection : false}
    >
      {headCell.allowsOrder ? (
        <TableSortLabel
          active={orderField === headCell.id}
          direction={orderField === headCell.id ? orderDirection : 'asc'}
          onClick={() => {
            const isAsc = orderField === headCell.id && orderDirection === 'asc';
            if (setOrderField && setOrderDirection) {
              setOrderDirection(isAsc ? 'desc' : 'asc');
              setOrderField(headCell.id);
            }
          }}
        >
          {headCell.label}
          {orderField === headCell.id ? (
            <Box component="span" sx={visuallyHidden}>
              {orderDirection === 'desc' ? 'sorted descending' : 'sorted ascending'}
            </Box>
          ) : null}
        </TableSortLabel>
      ) : (
        headCell.label
      )}
    </TableCell>
  );
};
