import { createTheme, ThemeProvider } from "@mui/material/styles";
import React from "react";
import { useStore } from "../store/store";

interface ThemeProps {}

export const Theme: React.FC<ThemeProps> = ({ children }) => {
  const { theme } = useStore();

  const materialTheme = React.useMemo(
    () =>
      createTheme({
        palette: {
          mode: theme,
        },
      }),
    [theme]
  );

  return <ThemeProvider theme={materialTheme}>{children}</ThemeProvider>;
};
