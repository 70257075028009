import React from 'react';
import { AutoAwesome } from '@mui/icons-material';
import { ActionMenuItemProps } from './MenuInterface';
import { useRevisionApproveEnhancementTaskMutation } from '../../../../../generated/graphql';
import { BaseMenuItem, useBaseMenuAction, refetchQueries } from '../BaseMenuItem';

export const EnhanceMenuItem: React.FC<ActionMenuItemProps> = ({
  handleMenuClick, task 
}) => {
  const [enhanceTask, { loading }] = useRevisionApproveEnhancementTaskMutation();
  const { action } = useBaseMenuAction(
    () => enhanceTask({
      refetchQueries,
      variables: {
        taskId: task.id.toString()
      }
    }),
    handleMenuClick,
    'Task sent to Enhancement',
    'Error sending task to enhancement'
  )

  return (
    <BaseMenuItem
      menuClick={action}
      loading={loading}
      icon={<AutoAwesome fontSize="small" />}
      text="Enhance"
    />
  );
}
