import axios from 'axios';
import shallow from 'zustand/shallow';

import { useStore } from '../store/store';

export const useSignOut = () => {
  const { setAccessToken, setRefreshToken, refreshToken } = useStore(
    (state) => ({
      setRefreshToken: state.setRefreshToken,
      setAccessToken: state.setAccessToken,
      refreshToken: state.refreshToken,
    }),
    shallow,
  );

  const logout = async () => {
    if (!refreshToken) {
      return;
    }

    try {
      const data =
        `token=` + refreshToken + `&client_id=${process.env.REACT_APP_AWS_COGNITO_CLIENT_ID}`;

      await axios.post(`${process.env.REACT_APP_AWS_COGNITO_AUTH}/oauth2/revoke`, data, {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
      });
      setAccessToken(null);
      setRefreshToken(null);
    } catch (err) {
      // eslint-disable-next-line no-console
      console.log(err);
    }
  };

  return logout;
};
