import React, { useState } from 'react';
import { Box, Paper } from '@mui/material';
import {
  DataGrid,
  GridSelectionModel,
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarDensitySelector,
} from '@mui/x-data-grid';

import {
  FilterPayloadFieldInput,
  InputMaybe,
  TaskStatuses,
  useTasksPaginatedQuery,
  CognitoGroups,
  TaskFieldsFilterInput
} from '../../generated/graphql';
import { useEZStore } from '../../store/store';
import { getPlainTextFromDraftAndClean } from '../../utils/helper';
import { TableToolbar } from './TableToolbar/TableToolbar';
import { LoadingScreen } from '../common/LoadingScreen/LoadingScreen';
import { IOrderDirection } from '../common/TableHeadCell/TableHeadCell';
import { useTaskTableColumns } from './useTaskTableColumns';
import { formatShortDate } from '../../utils/date';

export interface FieldsToOrder {
  id: number;
  parentTaskId: number;
  taskTypeId: number;
  title: string;
  content: string;
  status: string;
  sourceUrl: string;
  similarPropUrl: string;
  owners: string;
  dueDate: string;
  createdAt: string;
}

function CustomToolbar() {
  return (
    <GridToolbarContainer>
      {/* @ts-ignore: */}
      <GridToolbarColumnsButton />
      {/* @ts-ignore: */}
      <GridToolbarDensitySelector />
    </GridToolbarContainer>
  );
}

interface TableProps {}

export const TaskTable: React.FC<TableProps> = () => {
  const {
    filters,
    gridColumnVisibilityModel,
    setGridColumnVisibilityModel
  } = useEZStore();
  const [page, setPage] = useState(0);
  const [orderField, setOrderField] = useState<keyof FieldsToOrder>('id');
  const [orderDirection, setOrderDirection] = useState<IOrderDirection>('desc');
  const [pageSize, setPageSize] = useState(25);
  const [selectionModel, setSelectionModel] = useState<GridSelectionModel>([]);

  // TODO: persist/store this with zustand
  const [payloadFieldsFilter, setPayloadFieldsFilter] =
    useState<InputMaybe<FilterPayloadFieldInput[]>>(null);

  const { user: dataUser } = useEZStore();
  const columns = useTaskTableColumns();

  const taskFieldsFilter: TaskFieldsFilterInput = {
    typeId: filters.taskTypeId,
    ownerId: filters.owner?.id,
    status: TaskStatuses[filters.status as keyof typeof TaskStatuses],
    tenantId: filters.vendorId
  }

  const {
    loading,
    error,
    data: tasksData,
    previousData,
    refetch,
    variables,
  } = useTasksPaginatedQuery({
    notifyOnNetworkStatusChange: true,
    variables: {
      pageNumber: page + 1,
      pageSize: pageSize,
      search: filters.search,
      taskFieldsFilter,
      payloadFieldsFilter,
      orderField: orderField,
      orderDirection: orderDirection,
    },
  });

  const handleFilterPayloadFields = (newFilterPayloadField: FilterPayloadFieldInput) => {
    if (payloadFieldsFilter) {
      const payloadFieldsFilterCopy = [...payloadFieldsFilter];
      const payloadFieldsFilterCopyIndex = payloadFieldsFilterCopy.findIndex(
        (payloadField: FilterPayloadFieldInput) => {
          return payloadField.payloadField === newFilterPayloadField.payloadField;
        },
      );
      // if newFilterPayloadField is already in the state, then we need to update it
      if (payloadFieldsFilterCopyIndex !== -1) {
        payloadFieldsFilterCopy[payloadFieldsFilterCopyIndex].filterStatus =
          newFilterPayloadField.filterStatus;
        setPayloadFieldsFilter(payloadFieldsFilterCopy);
      } else {
        // if newFilterPayloadField is not in the state, then we need to add it
        setPayloadFieldsFilter([...payloadFieldsFilter, newFilterPayloadField]);
      }

      refetch({ ...variables, payloadFieldsFilter: newFilterPayloadField });
    } else {
      setPayloadFieldsFilter([newFilterPayloadField]);
    }
  };

  const clearFilterPayloadField = (payloadField: string) => {
    // find the payloadField in the state and remove it
    if (payloadFieldsFilter) {
      const payloadFieldsFilterCopy = [...payloadFieldsFilter];
      const payloadFieldsFilterCopyIndex = payloadFieldsFilterCopy.findIndex(
        (pf: FilterPayloadFieldInput) => {
          return pf.payloadField === payloadField;
        },
      );
      if (payloadFieldsFilterCopyIndex !== -1) {
        payloadFieldsFilterCopy.splice(payloadFieldsFilterCopyIndex, 1);
        setPayloadFieldsFilter(payloadFieldsFilterCopy);
      }
    }
  };

  const data = tasksData ? tasksData : previousData;

  if (loading && !previousData) {
    return <LoadingScreen />;
  }

  if ((error && !loading) || !data) {
    return <div>There was an error {error?.message}</div>;
  }

  const numSelected = selectionModel.length;
  const rows = data.tasksPaginated.items;

  // @todo magic strings
  const canMassAssign = dataUser?.me?.groups?.some(
    (group) => [CognitoGroups.HavenlyAdmin, CognitoGroups.TenantAdmin].includes(group)
  ) ?? false;

  const newRows = rows.map((row) => {
    return {
      ...row,
      content: getPlainTextFromDraftAndClean(row.content),
      owners:
        row.owners && row.owners.length
          ? row!.owners.map((owner: any) => owner!.name).join(', ')
          : 'Not assigned',
      dueDate: row.dueDate ? formatShortDate(row.dueDate) : 'Not set',
      createdAt: row.dueDate ? formatShortDate(row.createdAt) : 'Not set',
    };
  });

  return (
    <Box>
      <Box
        sx={{
          mt: 4,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <h2>Tasks ({data.tasksPaginated.itemCount})</h2>
      </Box>
      <Paper sx={{ width: '100%', mb: 4, mt: 2 }}>
        <TableToolbar
          loading={loading}
          selectedTaskIds={selectionModel as number[]}
          onResetSelectedTaskIds={() => setSelectionModel([])}
          numSelected={numSelected}
          handleFilterPayloadFields={handleFilterPayloadFields}
          clearFilterPayloadField={clearFilterPayloadField}
        />
        <Box sx={{ height: '900px', width: '100%' }}>
          <DataGrid
            sx={{ border: 'none' }}
            rows={newRows}
            columns={columns}
            checkboxSelection={canMassAssign}
            disableColumnFilter={true}
            disableSelectionOnClick={true}
            loading={loading}
            sortingMode="server"
            components={{ Toolbar: CustomToolbar }}
            onSortModelChange={(sortModel: any) => {
              setOrderDirection(sortModel[0].sort);
              setOrderField(sortModel[0].field);
            }}
            pagination
            paginationMode="server"
            pageSize={pageSize}
            onPageChange={(page) => setPage(page)}
            onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
            rowCount={data!.tasksPaginated!.itemCount}
            rowsPerPageOptions={[10, 25, 50, 100]}
            onSelectionModelChange={(newSelectionModel) => {
              setSelectionModel(newSelectionModel);
            }}
            selectionModel={selectionModel}
            columnVisibilityModel={gridColumnVisibilityModel}
            onColumnVisibilityModelChange={(newModel) => setGridColumnVisibilityModel(newModel)}
          />
        </Box>
      </Paper>
    </Box>
  );
};
